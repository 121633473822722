<div *ngIf="!acceptedCookie" class="alert alert-warning alert-dismissible alert-cookie fade show" role="alert">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-8 col-lg-9">
                <div class="pro-description">
                    {{'This site uses cookies' | translate}}. {{'By continuing to browse the site you are agreeing to our use of cookies' | translate}}. {{'Review our' | translate}} <a href="#" class="btn-link">{{'cookies information' | translate}}</a> {{'for more details' | translate}}.
                </div>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
                <button type="button" class="btn btn-secondary swipe-to-top" data-dismiss="alert" aria-label="Close" (click)="onAcceptCookie()">
                      {{'OK, I agree' | translate}}
                    </button>
            </div>
        </div>
    </div>
  </div>