<div class="loading-container" *ngIf="isLoading">
    <ngx-loading-bar color="#007bff"></ngx-loading-bar>
    <div class="loading-text">
      <p class="description ">Loading Your Experience...</p>
    </div>
  </div>
  <!-- <button (click)="onTestMe()">TestMe</button>   -->
  <app-task-modal
      #taskModal>
  </app-task-modal>
  <router-outlet *ngIf="!isLoading"></router-outlet>
  