<div class="theme-card">
    <h5 class="title-border">{{ title }}</h5>
    <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
        <ng-container *ngFor="let ticket of tickets | slice:0:1 ">  
            <ng-template carouselSlide>
                <div>
                  <div class="d-flex align-items-center" *ngFor="let ticket of tickets | slice:0:1" (click)="selectTicket(ticket)">
                    <div class="img-container position-relative">
                      <img class="img-fluid w-auto"
                           src="assets/images/ticket/ticket-background.jpg"
                           alt="Ticket Background">
                          <div class="overlay-text position-absolute top-50 start-50 translate-middle text-center font-size:40px">
                            {{ ticket.brand | titlecase }}
                        </div>
                  </div>                                
                    <!-- <div class="media-body align-self-center">
                      <a>
                      <h6>{{ ticket.title | titlecase }}</h6></a>
                      <h4>
                        {{ ticket.price | currency: productService.Currency.currency!:'symbol' }} 
                      </h4>
                    </div> -->
                  </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>