import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, catchError, interval, map, mergeMap, of, switchMap, tap } from "rxjs";

import { AuthService, ClientService } from "./../../services";
import * as AuthActions from './actions';
import { AppStateInterface } from "@app/core/types/appState.interface";
import { Store } from "@ngrx/store";
import * as ClientActions from '../client/actions';

@Injectable()
export class AuthEffects {

    

    getAuth$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.getAuth),
            mergeMap(() => {  
                 return this.authService.isUserLoggedIn().pipe(
                      map((isAuthLoggedIn) => AuthActions.getAuthState({ isAuthLoggedIn})))
             }))        
    );

    loginUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginUser),
            mergeMap((action) => {  
                 return this.authService.loginUser(action.clientId, action.userName, action.password, false, action.resultUrl).pipe(
                     map((isAuthLoggedIn) => AuthActions.getAuthState({ isAuthLoggedIn })))
             }))        
    );

    logoutUser$ = createEffect(() =>
    this.actions$.pipe(
        ofType(AuthActions.logoutUser),
        mergeMap(() => {  
             return this.authService.logoutUser().pipe(
                  map((isAuthLoggedIn) => AuthActions.logoutSuccess()))
         }))   
    );
    
    constructor(
        private actions$: Actions, 
        private store: Store<AppStateInterface>,
        private clientService: ClientService,
        private authService: AuthService) { }
}
