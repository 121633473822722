import { Component,ElementRef,EventEmitter,Input,OnDestroy,OnInit,Output,ViewChild,ViewEncapsulation } from '@angular/core';
import { ClientService } from '@app/core/services';
import * as moment from 'moment';
import { Subscription, timer } from 'rxjs';

declare var Checkout: any;

@Component({
    selector     : 'allxs-peachpayment',
    templateUrl  : './peachpayment.component.html',
    styleUrls    : ['./peachpayment.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PeachPaymentComponent  implements OnInit, OnDestroy
{
   updateTimer: any = null;
   updateTimerSubscription: Subscription;
   result: any = null; 
   orderId: string = "";

    @Output() requestCancelled: EventEmitter<any> = new EventEmitter();
    @Output() requestComplete: EventEmitter<any> = new EventEmitter();
    @Output() requestFail: EventEmitter<any> = new EventEmitter();
    @Output() requestExpired: EventEmitter<any> = new EventEmitter();
    @Output() onPaymentInit: EventEmitter<PeachPaymentComponent> = new EventEmitter();

    private checkout: any = null
    private mounted: boolean = false;
    constructor(private _clientService: ClientService){

    }
    ngOnInit(): void {
     this.onPaymentInit.emit(this);
    }

    ngOnDestroy(): void {
      this.updateTimerSubscription.unsubscribe();
    }

    public startPayment(orderId, entityid, requestid){
        this.orderId = orderId;
        this.mounted = true;
        this.checkout = Checkout.initiate({
            key: entityid,
            checkoutId: requestid, 
            events: { 
              onCompleted: (event: any) => {
                if (this.mounted){  
                  this.checkout.unmount();
                  this.mounted = false;
                }
                this.result = event;
              },
              onCancelled: (event: any) => {
                if (this.mounted){  
                  this.checkout.unmount();
                  this.mounted = false;
                }
                this.requestCancelled.emit(event)
              },
              onExpired: (event: any) => {
                if (this.mounted){  
                  this.checkout.unmount();
                  this.mounted = false;
                }
                this.requestExpired.emit(event)
              }
            }
          });
          this.checkout.render("#paymentform"); 
          this.startUpdateTimer();
    }

    onCancelled(){
      this.requestCancelled.emit("CANCEL");
    }

    public startUpdateTimer() {
      let that = this;
      if (this.updateTimer == null) {
        this.updateTimer = timer(6000, 6000);
        this.updateTimerSubscription = this.updateTimer.subscribe(x => {
          that._clientService.getClientTopupStatus(this.orderId)
            .subscribe({
              next: (data: any) => {
                
                var resultData = JSON.parse(data);
  
                if (resultData.Result == "wait") {
                  return;
                }

                if (this.mounted){  
                  this.checkout.unmount();
                  this.mounted = false;
                }

                if (resultData.Result == "OK") {
                  this.requestComplete.emit(resultData);
                }
                else {
                  this.requestFail.emit(this.result);
                }
              },
              error: (errorResponse: any) => {
                this.requestFail.emit(this.result);
              }
            })
        });
      }
    }
}