import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AdminLayoutComponent } from './shared/layout/admin-layout/admin-layout.component';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { content } from './shared/routes/content-routes';
import { admincontent } from './shared/routes/admin-routes';
import { PaymentComponent } from './payment/payment.component';
import { PaymentResolver } from './payment/payment.resolver';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home/landing',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: admincontent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pre-orders',
    loadChildren: () => import('./pre-order/pre-order.module').then(m => m.PreOrderModule),
    canActivate: [AuthGuard],
  },
  {
     path: 'whatsapppurchase/:slug',
     component: PaymentComponent,
     resolve: {
      data: PaymentResolver
    },
  },
  {
    path: 'wallet',
    component: ContentLayoutComponent,
    children: content,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
