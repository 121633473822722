<ng-template #paymentForm let-modal>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paymentFormLabel">Payment</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body center">
        <iframe [srcdoc]="iframeContainer?.nativeElement.innerHTML" width="100%" height="500px"></iframe>
      </div>
    </div>
  </ng-template>
  