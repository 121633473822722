import { Component, EventEmitter, Output, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { ModalDismissReasons, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-terms-modal',
    templateUrl: './terms-modal.component.html',
    styleUrls: ['./terms-modal.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
  })
  export class TermsModalComponent {
  
    public active = 'select';
    public closeResult: string;
    public modalOpen: boolean = false;
    public spinner
    modal: NgbModalRef;
  
    @ViewChild("termsModal", { static: false }) TermsModal: TemplateRef<string>;
    
    @Output() termsResult: EventEmitter<boolean> = new EventEmitter();
  
    constructor(
      //private store: Store, 
      private modalService: NgbModal) {
    }
  
    async openModal() {
      this.modalOpen = true;
      this.modal = this.modalService.open(this.TermsModal, {
        ariaLabelledBy: 'Terms-Modal',
        centered: true,
        windowClass: 'terms-modal'
      });
      this.modal.result.then((result) => {
        `Result ${result}`
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
      
    }
  
  
    private getDismissReason(reason: ModalDismissReasons): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }
  
    ngOnDestroy() {
      if (this.modalOpen) {
        this.modalService.dismissAll();
      }
    }
  
    onAgree(){
      this.termsResult.emit(true);
      this.modal.dismiss('close');
    }
    onDisagree(){
      this.termsResult.emit(false);
      this.modal.dismiss('close');
    }
    
  }
  