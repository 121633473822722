import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wallet-footer',
  templateUrl: './wallet-footer.component.html',
  styleUrls: ['./wallet-footer.component.scss']
})
export class WalletFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
