<ng-template class="theme-modal" #preOrderUserModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Pre-Order User Selection!</h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button> -->
        </div>
        <div class="modal-body center">
            <h4>Who would you like to order for?</h4>
            <div *ngFor="let linkedAccount of linkedAccounts">
                <div style="width: 100%; display: inline-block; margin-bottom: 10px;">
                    <a href="javascript:void(0)" class="btn btn-solid" style="width: 100%; display: inline-block;" (click)="selectedUser(linkedAccount.userId)">
                        {{ linkedAccount.fullName }} - {{ linkedAccount.userAccountNumber }}
                    </a>
                </div>
                <br>
            </div>
            
        </div>        
    </div>
</ng-template>