<div id="top"></div>
<ng-template #taskModal let-modal style="max-height: 95vh">
  <div class="modal-header">
    <h3>{{ 'Events/collections'}}</h3>
    <!-- <button *ngIf="allowDismiss" [class]="'btn btn-close'" 
      (click)="modal.dismiss('Cross click')">
      <i class="ri-close-line"></i>
    </button> -->
  </div>
  <div class="modal-body" style="min-height: 80vh; max-height: 80vh; height: 80vh; max-width: 100%; min-width: 100%; overflow-y: hidden;">
    <div *ngIf="mode == 'tasks'">
      <ul style="min-height: 100%; max-height: 100%; max-width: 100%; ">
        <li style="min-height: 200px; max-width: 100%; min-width: 100%;" *ngFor="let task of tasks$ |async">
          <app-task [task]="task" (onDismiss)="onDismiss($event)" (onPay)="onPayEvent($event)"></app-task>
        </li>
      </ul>
    </div>
    <div *ngIf="mode == 'processing'" style="min-height: 100vh; max-height: 50vh; max-width: 100vw; overflow-y: auto">
      <div>
        We are currently processing your payment!
      </div>
    </div>
    <div *ngIf="mode == 'showerror'">
      <allxs-task-error [task]="selectedTask" [message]="errorMesssage" (onDismiss)="onDismissErrorClick()"></allxs-task-error>
    </div>
    <div *ngIf="mode == 'processingpaymentcomplete'" style="min-height: 100vh; max-height: 50vh; max-width: 100vw; overflow-y: auto">
      <div class="row">
        <div class="col-12">
        Your payment was sucessfull!
      </div>
      </div>
      <div class="row">
        <div class="col-12">
        <button class="btn btn-default" (click)="onContinue()">Continue</button>
      </div>
      </div>
    </div>
    <div *ngIf="mode == 'paymentoption'" style="overflow-y: hidden">
      <allxs-payment-option [task]="selectedTask" (onDismiss)="onPaymentFail($event)" (onPayOnAccount)="onPaymentAccount($event)"
      (onPayBank)="onPaymentBank($event)"  (onPayCashless)="onPaymentCashless($event)"></allxs-payment-option>
    </div>
    <div  *ngIf="mode == 'payment'"  style="min-height: 100vh; max-height: 100vh; max-width: 100vw; overflow-y: auto; ">
     <allxs-peachpayment (onPaymentInit)="onPeachInit($event)"  
     (requestCancelled)="onPeachCancelled($event)" (requestComplete)="onPeachComplete($event)"></allxs-peachpayment>
       <!-- <allxs-iveri #ivery (iveriInit)="onIveriInit($event)" (iveriSuccess)="onIveriSuccess($event)" (iveriFail)="onIveriFail($event)"
        ></allxs-iveri>  -->
    </div>
    <div *ngIf="mode == 'wait'" class="h-100 d-flex align-items-center justify-content-center">
      Please Wait !
    </div>
    
  </div>
</ng-template>