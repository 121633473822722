import { environment } from "src/environments/environment";


export const config: any = {
   TRIOSAPI: environment.apiportal,
   GOOGLE_API_KEY: environment.GoogleMapAPIKey,
   version: environment.version,
   currencys: [
      {
         name: "US Doller",
         code: "USD",
         symbolLeft: "$",
         symbolRight: "",
         decimalPlaces: "2",
         Value: "1"

      },
      {
         name: "Candian Doller",
         code: "CAD",
         symbolLeft: "$",
         symbolRight: "",
         decimalPlaces: "2",
         Value: "1"

      },
      {
         name: "South African Rand",
         code: "ZAR",
         symbolLeft: "R",
         symbolRight: "",
         decimalPlaces: "2",
         Value: "1"
      }
   ]
}