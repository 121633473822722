import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { AuthService } from "../services";
import { Observable } from "rxjs";
import { inject, Injectable } from "@angular/core";
import { Store as nxgsStore } from "@ngxs/store";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const store = inject(nxgsStore);

      const token =  store.selectSnapshot(state => state.auth.access_token);
      //var token = this.auth.getToken();
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }
      return next.handle(request);
  }
}
