import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-task-information-modal',
  templateUrl: './task-information-modal.component.html',
  styleUrls: ['./task-information-modal.component.scss']
})
export class TaskInformationModalComponent implements OnInit, OnDestroy {

  @ViewChild("taskInfoModal", { static: false }) TaskInfoModal: TemplateRef<any>;
  public taskBody: string;

  public closeResult: string;
  public modalOpen: boolean = false;
  modal: NgbModalRef;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  async openModal(body: string) {
    this.modalOpen = true;
    this.modal = this.modalService.open(this.TaskInfoModal, {
      ariaLabelledBy: 'task-info-modal',
      centered: true,
      size: 'md',
      windowClass: 'Top'
    });

    this.modal.result.then((result) => {
      `Result ${result}`
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.taskBody = body;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
