import { Injectable } from "@angular/core";
import { Observable, delay, of } from "rxjs";
import { PostInterface } from "../types/post/post-interface.interface";


@Injectable()
  export class PostsService {
  
    getPosts() : Observable<PostInterface[]> {
        const posts =[
            {id: 1, title: "hello "},
            {id: 2, title: "World "}
        ]
        return of(posts).pipe(delay(2000));
    }
}
  