import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UserModel } from "@app/core/models/user/user.model";
import { UserService } from "@app/core/services";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { GetUserLinkedAccountsAction, User_ClearData, User_FetchUserAction, User_LoadOrdersAction, User_LoadTransactionsAction, User_SetClientAction, User_UpdateTransactions } from "../actions/user.action";
import { LoadUserAction, SelectUserLinkedAccountsAction, SetPhoneStateAction, SetUserPhoneStep1Action, SetUserPhoneStep2Action } from "../actions/user.action";
import { map, tap } from "rxjs";
import * as moment from 'moment';

export class UserStateModel {
  public client_id: number;
  public linkedAccounts: UserModel[];
  public currentUser: UserModel;
  public phoneState: string;
  public otpToken: string;
  public orders: [];
  public transactions: [];
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    client_id: 0,
    linkedAccounts: null,
    currentUser: null,
    phoneState: "INVALID",
    otpToken: "",
    orders: [],
    transactions: []
  }
})

@Injectable()
export class UserState {

  constructor(
    private ngsxStore: Store,
    private _userService: UserService,
    private _router: Router) {
  }

  @Selector()
  public static selectedLinkedAccounts(state: UserStateModel) {
    return state.linkedAccounts;
  }

  @Selector()
  public static currentUser(state: UserStateModel) {
    return state.currentUser;
  }

  @Selector()
  public static phoneState(state: UserStateModel) {
    return state.phoneState;
  }

  @Selector()
  public static orders(state: UserStateModel) {
    return state.orders;
  }
  @Selector()
  public static transactions(state: UserStateModel) {
    return state.transactions;
  }

  @Action(User_SetClientAction)
  public setClientAction(ctx: StateContext<UserStateModel>, action: User_SetClientAction) {
    ctx.patchState({ client_id: action.clientId });
  }

  @Action(SelectUserLinkedAccountsAction)
  public selectLinkedAccountsAction(ctx: StateContext<UserStateModel>, action: SelectUserLinkedAccountsAction) {
    ctx.patchState({ linkedAccounts: action.data });
  }

  @Action(User_FetchUserAction)
  public fetchUserAction(ctx: StateContext<UserStateModel>, action: User_FetchUserAction) {
    var state = ctx.getState();
    this._userService.fetchUser(state.client_id).subscribe({
      next: (user: UserModel) => {
        ctx.patchState({ currentUser: user });
      }
    })
  }


  @Action(LoadUserAction)
  public loadUserAction(ctx: StateContext<UserStateModel>, action: LoadUserAction) {
    ctx.patchState({ phoneState: action.userData.phoneVerified ? "VERIFIED" : "INVALID" })
    ctx.patchState({ currentUser: action.userData });
    this.ngsxStore.dispatch(new GetUserLinkedAccountsAction());
    this.ngsxStore.dispatch(new User_UpdateTransactions(action.userData.userId));
  }

  @Action(User_UpdateTransactions)
  public updateUserAction(ctx: StateContext<UserStateModel>, action: User_UpdateTransactions) {
    var today = new Date();
    today = new Date(new Date().setDate(today.getDate() + 1));
    var priorDate = new Date(new Date().setDate(today.getDate() - 30));
    this.ngsxStore.dispatch(new User_LoadOrdersAction(action.userId, moment(priorDate).format("DD MMM YYYY"), moment(today).format("DD MMM YYYY")));
    this.ngsxStore.dispatch(new User_LoadTransactionsAction(action.userId, moment(priorDate).format("DD MMM YYYY"), moment(today).format("DD MMM YYYY")));    
  }

  @Action(User_LoadOrdersAction)
  public loadOrdersAction(ctx: StateContext<UserStateModel>, action: User_LoadOrdersAction) {
    this._userService.fetchOrders(action.userId, action.fromDate, action.toDate).subscribe(data => {
      ctx.patchState({ orders: data?.orderLine ?? [] });
    });
  }

  @Action(User_LoadTransactionsAction)
  public loadTransactionsAction(ctx: StateContext<UserStateModel>, action: User_LoadTransactionsAction) {
    this._userService.fetchTransactions(action.userId, action.fromDate, action.toDate).subscribe(data => {
      ctx.patchState({ transactions: data.transactionLine ?? [] });
    });
  }


  @Action(GetUserLinkedAccountsAction)
  public getLinkedAccountsAction(ctx: StateContext<UserStateModel>, action: GetUserLinkedAccountsAction) {
    return this._userService.fetchLinkedAccounts().subscribe(data => {
      ctx.patchState({ linkedAccounts: data });
    })
  }

  @Action(SetPhoneStateAction)
  public setPhoneStateAction(ctx: StateContext<UserStateModel>, action: SetPhoneStateAction) {
    ctx.patchState({ phoneState: action.state })
  }

  @Action(SetUserPhoneStep1Action)
  public setUserPhoneAction(ctx: StateContext<UserStateModel>, action: SetUserPhoneStep1Action) {
    return this._userService.setUserPhone(action.phoneNumber).pipe(
      tap({
        next: (data: any) => {
          this.ngsxStore.dispatch(new SetPhoneStateAction("OTP"))
          ctx.patchState({ otpToken: data.token })
        },
        error: (errorResponse: any) => {
          this.ngsxStore.dispatch(new SetPhoneStateAction("VALID"));
        }
      })
    )
  }

  @Action(SetUserPhoneStep2Action)
  public setUserPhone2Action(ctx: StateContext<UserStateModel>, action: SetUserPhoneStep2Action) {
    let state = ctx.getState();
    return this._userService.setUserPhoneFromOTP(state.otpToken, action.pin).pipe(
      tap({
        next: (data: any) => {
          this._userService.fetchUser(1);
          this.ngsxStore.dispatch(new SetPhoneStateAction("VALID"));
        },
        error: (errorResponse: any) => {
          this.ngsxStore.dispatch(new SetPhoneStateAction("VALID"));
        }
      })
    )
  }

  @Action(User_ClearData)
  public clearUserData(ctx: StateContext<UserStateModel>, action: User_ClearData) {
    ctx.patchState({ orders: [], transactions: []});
  }

}
