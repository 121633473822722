import { AppStateInterface } from "@app/core/types/appState.interface";
import { createSelector } from "@ngrx/store";

export const clientFeature  = (state: AppStateInterface) => state.client;

export const clientSelector = createSelector(
    clientFeature, 
    (state) => state.client.clientData
);

export const clientErrorSelector = createSelector(
    clientFeature, 
    (state) => state.error
);

