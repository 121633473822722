import { Injectable, NgZone } from "@angular/core";
import { Action, Selector, State, StateContext, Store as ngsxStore } from "@ngxs/store";
import { LoginSuccess, SetClientAction, Logout, UpdateRegAction, AccountRegistered, SwitchAccount, SwitchToMainAccount, SkipStep, NextStep, GoogleLogin } from "../actions/auth.action";
import { AuthLoginModel } from "src/@allxs/model/auth/auth-login.model";
import { AuthService, StorageService, UserService } from "@app/core/services";
import { UserModel } from "@app/core/models/user/user.model";
import { Router } from "@angular/router";
import { AppStateInterface } from "@app/core/types/appState.interface";
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import * as ClientActions from '../../../app/core/store/client/actions';
import * as UserActions from '../../../app/core/store/user/actions';
import { LoadUserAction, User_ClearData, User_SetClientAction } from "../actions/user.action";
//import firebase from 'firebase/app';


export class AuthStateModel {
  public active_account_type: string;
  public client_id: number;
  public is_logged_in: boolean;
  public access_token: string;
  public logged_in_data: AuthLoginModel;
  public user: UserModel;
  public standby_user: UserModel;
  public registrationState: string;
  public resultUrl: string;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    active_account_type: 'account',
    client_id: null,
    is_logged_in: false,
    access_token: null,
    logged_in_data: { isLoggedIn: false, requiredActions: [] },
    user: null,
    standby_user: null,
    registrationState: null,
    resultUrl: '/'
  }
})

@Injectable()
export class AuthState {


  constructor(
    private zone: NgZone,
    private ngsxStore: ngsxStore,
    private store: Store<AppStateInterface>,
    private router: Router,
    private storageService: StorageService,
    private authService: AuthService,
    private userService: UserService) {

  }


  @Selector()
  public static isLoggedIn(state: AuthStateModel) {
    return state.is_logged_in;
  }

  @Selector()
  public static registrationState(state: AuthStateModel) {
    return state.registrationState;
  }

  @Selector()
  public static user(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  public static accountType(state: AuthStateModel) {
    
    return state.active_account_type;
  }


  @Action(SetClientAction)
  public setClientAction(ctx: StateContext<AuthStateModel>, action: SetClientAction) {
    ctx.patchState({ client_id: action.clientId });
    const auth_data = this.storageService.get('auth_token');

    if (auth_data && auth_data !== 'null') {

      const auth = JSON.parse(auth_data);

      if (auth.expires_in <= 0) {
        this.ngsxStore.dispatch(new Logout('Expired Token'));
        return;
      }
      var state = ctx.getState();
      ctx.patchState({ access_token: auth.access_token });

      this.userService.fetchUser(state.client_id).subscribe({
        next: (user: UserModel) => {
          this.fetchUser(ctx, user);
        }
      })
    }
    else {
      ctx.patchState({ is_logged_in: false, registrationState: null });
    }
  }

  @Action(LoginSuccess)
  public loginState(ctx: StateContext<AuthStateModel>, action: LoginSuccess) {
    let loginData = { isLoggedIn: action.token !== null, requiredActions: [] } as AuthLoginModel;
    const authStrData = action.data.access_token;

    var state = ctx.getState();
    ctx.patchState({
      access_token: authStrData,
      resultUrl: action.resultUrl
    })

    this.ngsxStore.dispatch(new User_SetClientAction(state.client_id));
    this.userService.fetchUser(state.client_id).subscribe({
      next: (user: UserModel) => {
        this.fetchUser(ctx, user);
        //this.store.dispatch(ClientActions.getClient());
      }
    })
  }


  @Action(Logout)
  public logoutState(ctx: StateContext<AuthStateModel>, action: Logout) {
    ctx.patchState({ 
      access_token: null, 
      is_logged_in: false, 
      client_id: -1, 
      logged_in_data: null, 
      registrationState: null, 
      user: null,
      standby_user: null,
      active_account_type: "none"
     });
    this.storageService.set('auth_token', null);
    this.store.dispatch(ClientActions.getClient());
  }

  @Action(UpdateRegAction)
  public updateUserRegistration(ctx: StateContext<AuthStateModel>, action: UpdateRegAction) {
    this.userService.updateUserReg(action.userData).subscribe(data => {
      this.fetchUser(ctx, data);
    });
  }

  @Action(AccountRegistered)
  public registerAccount(ctx: StateContext<AuthStateModel>, action: AccountRegistered) {


  }

  @Action(SwitchToMainAccount)
  public switchToMainAccount(ctx: StateContext<AuthStateModel>, action: SwitchToMainAccount) {
    var state = ctx.getState();
    this.authService.doInternalLogin(state.client_id, state.standby_user.userId)
      .subscribe({
        next: (data: any) => {
          let loginData = { isLoggedIn: data.token !== null, requiredActions: [] } as AuthLoginModel;
          const authStrData = data.access_token;
          this.ngsxStore.dispatch(new User_ClearData());
          var state = ctx.getState();
          ctx.patchState({
            active_account_type: "main",
            access_token: authStrData,
          })      
          this.userService.fetchUser(state.client_id).subscribe({
            next: (user: UserModel) => {
              this.fetchUser(ctx, user);
              this.store.dispatch(UserActions.getUser());
              this.userService.fetchLinkedAccounts();
            }
          }
          );
        },
        error: (errorResponse: any) => {
        }
      });
  }

  @Action(SwitchAccount)
  public switchAccount(ctx: StateContext<AuthStateModel>, action: SwitchAccount) {
    var state = ctx.getState();
    ctx.patchState({ standby_user: state.user });
    this.authService.doInternalLogin(state.client_id, action.userId)
      .subscribe({
        next: (data: any) => {
          let loginData = { isLoggedIn: data.token !== null, requiredActions: [] } as AuthLoginModel;
          const authStrData = data.access_token;
          this.ngsxStore.dispatch(new User_ClearData());
          
          ctx.patchState({
            active_account_type: "child",
            access_token: authStrData,
          });

          var state = ctx.getState();      
          this.userService.fetchUser(state.client_id).subscribe({
            next: (user: UserModel) => {
              this.store.dispatch(UserActions.getUser());
              this.fetchUser(ctx, user);
              this.userService.fetchLinkedAccounts();
            }
          }
          );
        },
        error: (errorResponse: any) => {
        }
      });
  }

  @Action(SkipStep)
  public skipStep(ctx: StateContext<AuthStateModel>, action: SkipStep) {
    var state = ctx.getState();
    var res1 = state.user.registrationState.filter(obj => obj !== action.step);
    var user = cloneDeep(state.user);
    user.registrationState = res1;
    ctx.patchState({ user: user });
    this.fetchUser(ctx, user);
  }

  @Action(NextStep)
  public nextStep(ctx: StateContext<AuthStateModel>, action: NextStep) {
  }

  @Action(GoogleLogin)
  public loginGoogle(ctx: StateContext<AuthStateModel>, action: GoogleLogin){
    var state = ctx.getState();
    //const provider = new firebase.auth.GoogleAuthProvider();
      //   provider.addScope('profile');
      //   provider.addScope('email');
      //   this.afAuth.auth.signInWithPopup(provider).then(
      //     res => {
      //       this.ngZone.run(() => {
      //         const result = res as any;
      //         const user = JSON.parse(JSON.stringify(result.user));
      //         //let userData = {user.}
      //         // console.log(result);
      //         var url = config.TRIOSAPI + '/api/account/ObtainLocalAccessToken?clientId=' + clientId + '&provider=google&externalaccesstoken=' + result.credential.accessToken;
      //         this.http.post<any>(url, user)
      //           .map(response => {
      //             return response;
      //           })
      //           .subscribe(
      //             data => {
      //               if (data == null) {
      //                 return;
      //               }
  
      //               // console.log("Hello Google Login", data);
      //               this.cookieService.set('currentToken', JSON.stringify(data));
      //               this.cookieService.set('currentRoles', JSON.stringify(data.roles));
      //               //localStorage.setItem('currentToken', JSON.stringify(data));
      //               //localStorage.setItem('currentRoles', JSON.stringify(data.roles));
      //               that.authData = data;
      //               that.authStrData = JSON.stringify(data);
      //               that.roleData = that.authData.roles;
      //               that.authState = 1;
      //               that.isLoggedIn = true;
  
      //               this.cookieService.set('currentRoles', data.roles);
      //               that.router.navigate(["/dashboard/analytics"]);
      //               subscriber.next(data);
      //             },
      //             errorResponse => {
      //               subscriber.error({
      //                 error: errorResponse,
      //                 info: result.additionalUserInfo
      //               });
      //             }
      //           );
      //       });
      //     },
      //     err => {
      //       // console.log(err);
      //       subscriber.error(err);
      //     }
      //   );
      // });
    //}
  
  }


  private fetchUser(ctx: StateContext<AuthStateModel>, user: UserModel) {
    this.zone.run(() => {
      var state = ctx.getState();
      ctx.patchState({ user: user })
      if (user.registrationState.length == 0 || (user.registrationState.length == 1 && user.registrationState[0] == 'NO_USER_CONTACT')) {
        ctx.patchState({ is_logged_in: true });
        this.store.dispatch(UserActions.setUser({ data: user })); // For Now use old store
        this.ngsxStore.dispatch(new LoadUserAction(user)); // For New Store
        if (state.resultUrl && state.resultUrl !== '/') {
          this.router.navigate([state.resultUrl]);
          ctx.patchState({ resultUrl: '/' });
        }
        else{
           this.router.navigate(['/wallet/dashboard/landing']);
        }
      }
      else {
        if (user.registrationState.includes('NO_USER_DETAIL') && (state.active_account_type != 'child')) {
          ctx.patchState({ registrationState: 'NO_USER_DETAIL' });
          ctx.patchState({ is_logged_in: false });
          //this.store.dispatch(UserActions.setUser({ data: user })); // For Now use old store
          this.router.navigate(['/home/auth/login']);
        }
        else if (user.registrationState.includes('NO_PHONE_NUMBER') && (state.active_account_type != 'child')) {
          ctx.patchState({ registrationState: 'NO_PHONE_NUMBER' });
          ctx.patchState({ is_logged_in: false });
          //this.store.dispatch(UserActions.setUser({ data: user })); // For Now use old store
          this.router.navigate(['/home/auth/login']);
        }
        else if (user.registrationState.includes('NO_USER_CHILD') && (state.active_account_type != 'child')) {
          ctx.patchState({ registrationState: 'NO_USER_CHILD' });
          this.ngsxStore.dispatch(new LoadUserAction(user)); // For New Store
          ctx.patchState({ is_logged_in: true });
          this.router.navigate(['/home/auth/login']);
        }
      }
    });
  }
}