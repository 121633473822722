import { inject, Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import { Store as nxgsStore } from "@ngxs/store";
import { Logout } from 'src/@allxs/store/actions/auth.action';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
    
    

    constructor() {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const store = inject(nxgsStore);
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                
                let errorMsg = '';
                if (error.error) {
                    if(error.status === 401){
                        
                        store.dispatch(new Logout("NoToken"));
                        errorMsg = 'Server is down, please try again later';
                    }
                }
                return throwError(errorMsg);
            })
        );
    }
}