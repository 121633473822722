import { TimeSlotInventoryModel } from "src/@allxs/model/inventory/timeslotinventory.model";
import { TimeSlotInventoryUpdateDataModel } from "src/@allxs/model/inventory/timeslotinventorydata.model";

export class GetProductInventoryAction {
    static readonly type = '[Product] Get product inventory';
    constructor(public productId: number) {}
 }


 export class  HoldInventoryAction {
    static readonly type = '[Product] Hold Inventory';
    constructor(public timeSlot: TimeSlotInventoryModel,  public productId: number) {}
 }

 export class  ReleaseInventoryAction {
   static readonly type = '[Product] Release Inventory';
   constructor(public timeSlot: TimeSlotInventoryUpdateDataModel) {}
}
