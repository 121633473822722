import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { isLoggedInSelector } from '@app/core/store/auth/selector';
import { AppStateInterface } from '@app/core/types/appState.interface';
import { AuthorizedUser } from '@app/shared/classes/authorized';
import { select, Store } from '@ngrx/store';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AuthState } from 'src/@allxs/store/state/auth.state';
import { Product } from 'src/app/shared/classes/product';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-cart-variation',
  templateUrl: './cart-variation.component.html',
  styleUrls: ['./cart-variation.component.scss']
})
export class CartVariationComponent implements OnInit, OnDestroy {

  @Input() direction: string = 'right'; // Default Direction Right

  public products: Product[] = [];

  private subscription: Subscription;
  public isLoggedIn: boolean = false;
  @Select(AuthState.isLoggedIn) isLoggedIn$: Observable<boolean>;
  
  constructor(public productService: ProductService,
    private router: Router,
    private store: Store<AppStateInterface>) {
    this.productService.cartItems.subscribe(response => this.products = response);
    this.subscription = new Subscription();
    this.subscription.add(this.isLoggedIn$.subscribe(data => {
      this.isLoggedIn = data;
   }));
  }

  ngOnInit(): void {
    this.productService.OpenCart = false;
  }

  closeCart() {
    this.productService.OpenCart = false;
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  ngOnDestroy(): void {
    this.closeCart();
  }

  onCheckOut() {
    this.router.navigate(['/home/account/checkout'])
    .catch(error => console.log('Navigation error:', error));
    // if (this.isLoggedIn) {
    //   this.router.navigate(['/home/account/checkout'])
    //   .catch(error => console.log('Navigation error:', error));
    // } else {
    //   let authorizedUser: AuthorizedUser = {
    //     redirectToCheckout: true
    //   }
    //   const navigationExtras: NavigationExtras = {
    //     state: { data: authorizedUser }
    //   };
      
    //   this.router.navigate(['/home/auth/login'], navigationExtras)
    //   .catch(error => console.log('Navigation error:', error));     
    // }
  }

}

