import { Injectable } from "@angular/core";
import { UserService } from "@app/core/services/user.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap, of } from "rxjs";
import * as UserActions from './actions';
import { AppStateInterface } from "@app/core/types/appState.interface";
import { Store } from "@ngrx/store";


@Injectable()
export class UserEffects {


    getUser$ = createEffect(() => this.actions$.pipe(
               ofType(UserActions.getUser),
               mergeMap(() => {  
                 return this.userService.fetchUser(1)
                 .pipe(
                  map((data) => UserActions.getUserSuccess({ data })))
                }))        
    );


    // getLogonSuccess$ = createEffect(() => this.actions$.pipe(
    //   ofType(AuthActions.loginSuccess),
    //   map((data: any) => {
    //     this.store.dispatch(UserActions.getUser());
    //     return UserActions.getUser()}))      
    //  );        


    constructor(
      private store: Store<AppStateInterface>,
      private actions$: Actions, 
      private userService: UserService) { }
}