import { Component, Input, ViewChild, TemplateRef, Output, EventEmitter, ViewEncapsulation, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ContentChild, Injector, ViewChildren } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbNav, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store as ngxStore } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { TaskInterface, TasksInterface } from 'src/@allxs/interface/task.interface';
import { TaskModel } from 'src/@allxs/model/task/task.model';
import { WebOrderModel } from '@app/core/models/weborder/weborder.model';
import { ClientService } from '@app/core/services';
import { Store, select } from '@ngrx/store';

import { clientSelector } from '@app/core/store/client/selectors';
import { getUserSelector } from '@app/core/store/user/selectors';

import { AppStateInterface } from '@app/core/types/appState.interface';
import { ClientModel } from '@app/core/models/client/client.model';
import { UserModel } from '@app/core/models/user/user.model';
import { WebOrderItemModel } from '@app/core/models/weborder/weborder-item.model';
import { PeachPaymentComponent } from '../peachpayment/peachpayment.component';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { IveriComponent } from '../iveri/iveri.component';
import { TaskState } from 'src/@allxs/store/state/task.state';
import { WebTaskOrderModel } from 'src/@allxs/model/task/weborder.model';
import { CheckForTasksAction, DismissTaskAction } from 'src/@allxs/store/actions/task.action';

@Component({
  selector: 'app-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class TaskModalComponent implements OnInit, AfterViewInit, OnDestroy {

  public active = 'select';
  public closeResult: string;
  public modalOpen: boolean = false;
  public spinner;
  public modalRef: NgbModalRef = null;
  public allowDismiss: boolean = true;
  public mode: string = "tasks";
  private iveri: IveriComponent = null;
  public selectedTask: TaskInterface;
  private clientData: ClientModel;
  private userData: UserModel;
  private balance: Number = 0;
  public entityId: string = "";
  public requestId: string = "";
  private peachPayments: PeachPaymentComponent = null;
  public errorMesssage: string = "";

  @ViewChild("taskModal", { static: false }) TaskModal: TemplateRef<any>;
  @ViewChild("top", { static: false }) public set top(content: any) {
    if (content) { // initially setter gets called with undefined
      console.log("a");
    }
  }

  @Select(TaskState.tasks) tasks$: Observable<any>;
  @Output('onDismiss')  onModelClose = new EventEmitter();

  private subscription: Subscription = new Subscription();
  constructor(
    private viewPortScroller: ViewportScroller,
    private _clientService: ClientService,
    private store: Store<AppStateInterface>,
    private _ngxStore: ngxStore,
    private _router: Router,
    private changeRef: ChangeDetectorRef,
    private modalService: NgbModal) {
  }

  ngAfterViewInit(): void {
    let top = document.getElementById('top');
  }


  ngOnInit(): void {
    this.subscription.add(this.store.pipe(select(clientSelector)).subscribe(data => {
      if (data) {
        this.clientData = data.clientData;
      }
    }));
    this.subscription.add(this.store.pipe(select(getUserSelector)).subscribe(data => {
      if (data) {
        this.userData = data.userData!;
        this.balance = data.userData?.account.balance ?? 0;
      }
    }));
    this.subscription.add(this.tasks$.subscribe({
      next: (result: any) => {
        if (result) {
          if (result?.length > 0 && !this.modalOpen) {
            this.openModal();
          }
          else {

            if (this.modalRef != null){
              this.modalRef.close();
              this.modalOpen = false;
              if (this.onModelClose)
                this.onModelClose.emit();
            }
          }
        }
      }
    })
    );
  }


  openModal() {
    if (!this.modalOpen) {
      this.modalOpen = true;
      this.modalRef = this.modalService.open(this.TaskModal, {
        ariaLabelledBy: 'Task-Modal',
        centered: true,
        size: 'tasksize',
        windowClass: 'task-modal',
        backdrop: 'static',
        keyboard: false,
        beforeDismiss() {
          return false;
        },
      });
      this.modalRef.result.then((result) => {
        `Result ${result}`
        this.closeResult = `Closed with: ${result}`;
        if (this.onModelClose)
          this.onModelClose.emit();
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        if (this.onModelClose)
          this.onModelClose.emit();
      });
    }
  }

  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.modalOpen) {
      this.modalService.dismissAll();
      if (this.onModelClose)
        this.onModelClose.emit();
    }
  }

  onIveriInit(event) {
    this.iveri = event;
    this.iveri.postToIveri("https://clientapidev.allxs.co.za", "D0BC0860-24D5-44C2-915E-857DA37C0ABD", "341", "", "b5164f18-81d1-4616-bcd3-d971195427dd", 1);
  }

  onIveriSuccess(event) {
  }

  onPaymentFail($event) {
    this.mode = "tasks";
  }

  onPaymentAccount($event) {
    this.mode = "wait";
  }

  onPaymentBank(event) {
    this.mode = "payment";
    let that = this;
    var item = new WebOrderItemModel()
    item.productId = event.productId;
    item.quantity = 1;
    item.totalAmount = event.amount;

    let order = new WebOrderModel();
    order.items = [];
    order.userId = this.userData.userId;
    order.vendorId = event.vendorId;
    order.orderDate = new Date();
    order.orderTotal = event.amount;
    order.items.push(item);
    let webTaskOrder = new WebTaskOrderModel();
    webTaskOrder.order = order;
    webTaskOrder.task = event;
    this._clientService.placeWebTaskCardOrder(this.clientData.clientId, webTaskOrder)
      .subscribe({
        next: (data: any) => {
          if (data == null)
            return;
          this.entityId = data.entityId;
          this.requestId = data.requestId;
          this.peachPayments.startPayment(data.depositId, data.entityId, data.requestId);
          //this.mode = "processingpaymentcomplete"; 
        },
        error: (errorResponse: any) => {
          this.errorMesssage = errorResponse.error.Message;
          this.mode = "showerror"
        }
      });
  }

  onPeachInit(event) {
    this.peachPayments = event;
  }


  onPeachComplete($event) {
    this.mode = "tasks";
    window.scroll(0, 4);
    this._ngxStore.dispatch(new CheckForTasksAction()).subscribe({next: (data :any)=>{
     this.modalRef.componentInstance
     this.modalRef.close();
     this.modalOpen = false;  
    }});
    
  }


  onPeachCancelled(event) {
    this.mode = "tasks";
    window.scroll(0, 4);
    //window.scroll(0,0);
    //  this.changeRef.detectChanges();
    //  this.modalRef.componentInstance
    //  this.modalRef.close();
    //  this.modalOpen = false;
  }

  onDismissErrorClick() {
    this.mode = "tasks";
  }

  onPaymentCashless(event) {

    this.mode = "processing";
    let that = this;
    var item = new WebOrderItemModel()
    item.productId = event.productId;
    item.quantity = 1;
    item.totalAmount = event.amount;

    let order = new WebOrderModel();
    order.items = [];
    order.userId = this.userData.userId;
    order.vendorId = event.vendorId;
    order.orderDate = new Date();
    order.orderTotal = event.amount;
    order.items.push(item);
    let webTaskOrder = new WebTaskOrderModel();
    webTaskOrder.order = order;
    webTaskOrder.task = event;
    this._clientService.placeWebTaskOrder(this.clientData.clientId, webTaskOrder)
      .subscribe({
        next: (data: any) => {
          this.mode = "processingpaymentcomplete";
        },
        error: (errorResponse: any) => {
          this.errorMesssage = errorResponse.message;
          this.mode = "showerror"
        }
      });

    // this.getTotal.subscribe(total => {
    //   this.productTotal = total;
    // });

    // order.orderDate = new Date();
    // order.orderTotal = this.productTotal;
    // order.instructions = this.notes;
    // if (this.isLoggedIn || this.isGuestLoggedIn) {
    //   order.userId = this.userData.userId;
    // }
    // order.items = [];
    // this.products.forEach(product => {
    //   let webOrderItemModel: WebOrderItemModel = {
    //     productId: product.id,
    //     quantity: product.quantity,
    //     totalAmount: product.quantity * product.price,
    //     data: ''
    //   };

    //   order.items.push(webOrderItemModel);
    // })

    // that.hasError = false;

    // that.isProcessingPayment = true;
    // this.spinner.show();

    // this.clientService.placeWebOrder(this.clientData.clientId, order, this.isLoggedIn)
    //   .subscribe({
    //     next: (data: any) => {
    //       that.hasError = false;
    //       that.vendorOrderResponse = data.vendorResponse;
    //       that.vendorOrderResponse.forEach(x => {
    //         x.state = x.state.toUpperCase()
    //         if (!x.state.toLocaleLowerCase().includes("ok")) {
    //           that.hasError = true;
    //           // that.toastr.error(x.errorMessage, "Allxs Payment Failed!", { enableHtml: true });
    //           Swal.fire({
    //             icon: "error",
    //             title: "Allxs Payment Failed!",
    //             text: x.errorMessage,
    //             confirmButtonColor: "#3085d6"
    //           });
    //         } else {
    //           if (!that.hasError) {
    //             let orderSummary: OrderSummary = {
    //               vendorOrderResponse: that.vendorOrderResponse[0],
    //               productSummary: this.products,
    //               paymentMethod: 'Allxs Account',
    //               orderTotal: this.productTotal
    //             }

    //             const navigationExtras: NavigationExtras = {
    //               state: { data: orderSummary }
    //             };

    //             this.router.navigate(['/home/account/order/result'], navigationExtras)
    //               .catch(error => console.log('Navigation error:', error));
    //             this.productService.clearCart();
    //           }
    //         }

    //         that.isProcessingPayment = false;
    //         this.spinner.hide();
    //       });
    //     },
    //     error: (error: any) => {
    //       that.isProcessingPayment = false;
    //       this.spinner.hide();
    //       // that.toastr.error('Post fail!', 'Oh no you order failed ');
    //       Swal.fire({
    //         icon: "error",
    //         title: "Oh No!",
    //         text: "Your order failed",
    //         confirmButtonColor: "#3085d6"
    //       });
    //     }
    //   });
  }


  onContinue() {
    this.mode = "tasks";
    this._ngxStore.dispatch(new CheckForTasksAction());
  }

  onIveriFail($event) {
    this.mode = "tasks";
  }

  onDismiss(task: TaskInterface) {
    this._ngxStore.dispatch(new DismissTaskAction(task))
  }

  onPayEvent(task: TaskInterface) {
    this.selectedTask = task;
    this.mode = "paymentoption";
    //this.Iveri.postToIveri("https://clientapidev.allxs.co.za","D0BC0860-24D5-44C2-915E-857DA37C0ABD","341","","b5164f18-81d1-4616-bcd3-d971195427dd", 1);
  }
}
