import { Component,EventEmitter,Input,Output,ViewEncapsulation } from '@angular/core';
import { TaskInterface } from 'src/@allxs/interface/task.interface';



@Component({
    selector     : 'allxs-payment-option',
    templateUrl  : './payment-option.component.html',
    styleUrls    : ['./payment-option.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PaymentOptionComponent 
{
    @Input() task: TaskInterface;
    @Output('onDismiss') onDismiss = new EventEmitter<TaskInterface>();
    @Output('onPayOnAccount') onPayOnAccount  = new EventEmitter<TaskInterface>();
    @Output('onPayCashless') onPayCashless  = new EventEmitter<TaskInterface>();
    @Output('onPayBank')  onPayBank  = new EventEmitter<TaskInterface>();

    //@Select(TaskState.tasks) tasks$: Observable<TaskModel>;
    constructor()
    {

    }
    
    onDismissClick(){
        this.onDismiss.emit(this.task);
    }

    onPayWithBank(){
        this.onPayBank.emit(this.task);
    }
    onPayWithCashless(){
        this.onPayCashless.emit(this.task);
    }
    onPayWithOnAccount(){
        this.onPayOnAccount.emit(this.task);
    }

}
