
<div class="img-wrapper" [ngClass]="{selected: selected}">
    <div class="lable-block">
    </div>
    <div class="front">
        <a (click)="selectMenu(menu)">
            <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
                [lazyLoad]="ImageSrc" class="img-fluid lazy-loading"
                alt="{{ ImageSrc }}" />
        </a>
    </div>
    <div class="cart-info cart-wrap">
        <h4>{{ _menu?.menuName | titlecase }}</h4>
    </div>
</div>
<div class="product-detail">
    <br/>
    <div>
        <a>
            <span *ngIf="itemCount > 0" class="badge bg-secondary" style="font-size: x-large;">{{ itemCount }}</span>
            <span *ngIf="orderItemCount > 0" class="badge" style="background-color: blue; font-size: x-large;">{{ orderItemCount }}</span>
            <br/>
            <br/>
            <h6>{{ _menu?.menuDescription | titlecase }}</h6>
        </a>
    </div>
</div>