import { TaskInterface } from "src/@allxs/interface/task.interface";

export class ShowTaskAction {
    static readonly type = '[Task] Show task action';
    constructor() {}
 }


 export class HideTaskAction {
    static readonly type = '[Task] Hise task action';
    constructor() {}
 }

 export class CheckForTasksAction {
   static readonly type = '[Task] Check';
   constructor(public force: boolean = false ) {}
}

export class CheckTaskAction {
   static readonly type = '[Task] Check One';
   constructor(public task :string) {}
}
 
export class DismissTaskAction {
   static readonly type = '[Task] Dismiss';
   constructor(public task: TaskInterface) {}
}
