import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ClientModel } from '@app/core/models/client/client.model';
import { UserModel } from '@app/core/models/user/user.model';
import { WebOrderModel } from '@app/core/models/weborder/weborder.model';
import { ClientService } from '@app/core/services';
import { clientSelector } from '@app/core/store/client/selectors';
import { getUserSelector } from '@app/core/store/user/selectors';
import { AppStateInterface } from '@app/core/types/appState.interface';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IveriComponent } from 'src/@allxs/components/iveri/iveri.component';
import { PeachPaymentComponent } from 'src/@allxs/components/peachpayment/peachpayment.component';
import { TaskInterface } from 'src/@allxs/interface/task.interface';
import { WebOrderItemModel } from 'src/@allxs/model/task/weborder-item.model';
import { WebTaskOrderModel } from 'src/@allxs/model/task/weborder.model';
import { CheckForTasksAction } from 'src/@allxs/store/actions/task.action';
import { Select, Store as ngxStore } from '@ngxs/store';
import * as moment from 'moment';

@Component({
  selector: 'app-task-payment-modal',
  templateUrl: './task-payment-modal.component.html',
  styleUrls: ['./task-payment-modal.component.scss']
})
export class TaskPaymentModalComponent implements OnInit, OnDestroy {

  @ViewChild("taskPaymentModal", { static: false }) TaskPaymentModal: TemplateRef<any>;
  public task: TaskInterface;

  public closeResult: string;
  public modalOpen: boolean = false;
  modal: NgbModalRef;

  public active = 'select';
  public spinner;
  public modalRef: NgbModalRef = null;
  public allowDismiss: boolean = true;
  public mode: string = "paymentoption";
  private iveri: IveriComponent = null;
  public selectedTask: TaskInterface;
  private clientData: ClientModel;
  private userData: UserModel;
  public entityId: string = "";
  public requestId: string = "";
  private peachPayments: PeachPaymentComponent = null;
  public errorMesssage: string = "";

  public taskDeadline: string;
  public dayOfWeek: string = "...";
  public day: string = "...";
  public month: string = "...";
  public time: string = "...";

  private subscription : Subscription = new Subscription();

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private _clientService: ClientService,
    private _ngxStore: ngxStore,
    private store: Store<AppStateInterface>) {
    this.subscription.add(this.store.pipe(select(clientSelector)).subscribe(data => {
      if (data) {
        this.clientData = data.clientData;
      }
    }));
    this.subscription.add(this.store.pipe(select(getUserSelector)).subscribe(data => {
      if (data) {
        this.userData = data.userData!;
      }
    }));
  }


  ngOnInit(): void {
  }

  async openModal(task: TaskInterface) {
    this.modalOpen = true;
    this.modal = this.modalService.open(this.TaskPaymentModal, {
      ariaLabelledBy: 'task-payment-modal',
      centered: true,
      size: 'md',
      windowClass: 'Top',
      //backdrop: 'static'
    });

    this.modal.result.then((result) => {
      `Result ${result}`
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.task = task;
    this.onPayEvent(this.task);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
  onIveriInit(event) {
    this.iveri = event;
    this.iveri.postToIveri("https://clientapidev.allxs.co.za", "D0BC0860-24D5-44C2-915E-857DA37C0ABD", "341", "", "b5164f18-81d1-4616-bcd3-d971195427dd", 1);
  }

  onIveriSuccess(event) {
  }

  onPaymentFail($event) {
    this.mode = "tasks";
  }

  onPaymentAccount($event) {
    this.mode = "wait";
  }

  onPaymentBank(event) {
    this.mode = "payment";
    let that = this;
    var item = new WebOrderItemModel()
    item.productId = event.productId;
    item.quantity = 1;
    item.totalAmount = event.amount;

    let order = new WebOrderModel();
    order.items = [];
    order.userId = this.userData.userId;
    order.vendorId = event.vendorId;
    order.orderDate = new Date();
    order.orderTotal = event.amount;
    order.items.push(item);
    let webTaskOrder = new WebTaskOrderModel();
    webTaskOrder.order = order;
    webTaskOrder.task = event;
    this._clientService.placeWebTaskCardOrder(this.clientData.clientId, webTaskOrder)
      .subscribe({
        next: (data: any) => {
          if (data == null)
            return;
          this.entityId = data.entityId;
          this.requestId = data.requestId;
          this.peachPayments.startPayment(data.depositId, data.entityId, data.requestId);
          //this.mode = "processingpaymentcomplete"; 
        },
        error: (errorResponse: any) => {
          this.errorMesssage = errorResponse.error.Message;
          this.mode = "showerror"
        }
      });
  }

  onPeachInit(event) {
    this.peachPayments = event;
  }


  onPeachComplete($event) {
    this.mode = "tasks";
    window.scroll(0, 2);
    // this.modalRef.componentInstance
    // this.modalRef.close();
    // this.modalOpen = false;
  }


  onPeachCancelled(event) {
    this.mode = "tasks";
    window.scroll(0, 2);
    //window.scroll(0,0);
    //  this.changeRef.detectChanges();
    //  this.modalRef.componentInstance
    //  this.modalRef.close();
    //  this.modalOpen = false;
  }

  onDismissErrorClick() {
    this.mode = "tasks";
  }

  onPaymentCashless(event) {

    this.mode = "processing";
    let that = this;
    var item = new WebOrderItemModel()
    item.productId = event.productId;
    item.quantity = 1;
    item.totalAmount = event.amount;

    let order = new WebOrderModel();
    order.items = [];
    order.userId = this.userData.userId;
    order.vendorId = event.vendorId;
    order.orderDate = new Date();
    order.orderTotal = event.amount;
    
    order.items.push(item);
    let webTaskOrder = new WebTaskOrderModel();
    webTaskOrder.order = order;
    webTaskOrder.task = event;
    this._clientService.placeWebTaskOrder(this.clientData.clientId, webTaskOrder)
      .subscribe({
        next: (data: any) => {
          this.mode = "processingpaymentcomplete";
        },
        error: (errorResponse: any) => {
          this.errorMesssage = errorResponse.message;
          this.mode = "showerror"
        }
      });

    // this.getTotal.subscribe(total => {
    //   this.productTotal = total;
    // });

    // order.orderDate = new Date();
    // order.orderTotal = this.productTotal;
    // order.instructions = this.notes;
    // if (this.isLoggedIn || this.isGuestLoggedIn) {
    //   order.userId = this.userData.userId;
    // }
    // order.items = [];
    // this.products.forEach(product => {
    //   let webOrderItemModel: WebOrderItemModel = {
    //     productId: product.id,
    //     quantity: product.quantity,
    //     totalAmount: product.quantity * product.price,
    //     data: ''
    //   };

    //   order.items.push(webOrderItemModel);
    // })

    // that.hasError = false;

    // that.isProcessingPayment = true;
    // this.spinner.show();

    // this.clientService.placeWebOrder(this.clientData.clientId, order, this.isLoggedIn)
    //   .subscribe({
    //     next: (data: any) => {
    //       that.hasError = false;
    //       that.vendorOrderResponse = data.vendorResponse;
    //       that.vendorOrderResponse.forEach(x => {
    //         x.state = x.state.toUpperCase()
    //         if (!x.state.toLocaleLowerCase().includes("ok")) {
    //           that.hasError = true;
    //           // that.toastr.error(x.errorMessage, "Allxs Payment Failed!", { enableHtml: true });
    //           Swal.fire({
    //             icon: "error",
    //             title: "Allxs Payment Failed!",
    //             text: x.errorMessage,
    //             confirmButtonColor: "#3085d6"
    //           });
    //         } else {
    //           if (!that.hasError) {
    //             let orderSummary: OrderSummary = {
    //               vendorOrderResponse: that.vendorOrderResponse[0],
    //               productSummary: this.products,
    //               paymentMethod: 'Allxs Account',
    //               orderTotal: this.productTotal
    //             }

    //             const navigationExtras: NavigationExtras = {
    //               state: { data: orderSummary }
    //             };

    //             this.router.navigate(['/home/account/order/result'], navigationExtras)
    //               .catch(error => console.log('Navigation error:', error));
    //             this.productService.clearCart();
    //           }
    //         }

    //         that.isProcessingPayment = false;
    //         this.spinner.hide();
    //       });
    //     },
    //     error: (error: any) => {
    //       that.isProcessingPayment = false;
    //       this.spinner.hide();
    //       // that.toastr.error('Post fail!', 'Oh no you order failed ');
    //       Swal.fire({
    //         icon: "error",
    //         title: "Oh No!",
    //         text: "Your order failed",
    //         confirmButtonColor: "#3085d6"
    //       });
    //     }
    //   });
  }

  onContinue(){
    this.mode = "tasks";
    this._ngxStore.dispatch(new CheckForTasksAction());
  }

  onIveriFail($event) {
    this.mode = "tasks";
  }

  // onDismiss(task: TaskInterface) {
  //   this._ngxStore.dispatch(new DismissTaskAction(task))
  // }

  onPayEvent(task: TaskInterface) {
    this.selectedTask = task;
    this.mode = "paymentoption";
    //this.Iveri.postToIveri("https://clientapidev.allxs.co.za","D0BC0860-24D5-44C2-915E-857DA37C0ABD","341","","b5164f18-81d1-4616-bcd3-d971195427dd", 1);
  }
}
