
import { NgModule } from '@angular/core';
import { CurencyPipe } from './curency/curency.pipe';
import { MomentPipe } from './moment/moment.pipe';


@NgModule({
    declarations: [CurencyPipe, MomentPipe],
    imports: [],
    exports: [CurencyPipe,MomentPipe]
})
export class PipesModule { }
