import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { EventSummary } from '@app/shared/classes/event';
import { Product } from '@app/shared/classes/product';
import { ProductService } from '@app/shared/services/product.service';
import { CartModalComponent } from '../modal/cart-modal/cart-modal.component';
import { QuickViewComponent } from '../modal/quick-view/quick-view.component';

@Component({
  selector: 'app-events-item-box',
  templateUrl: './events-item-box.component.html',
  styleUrls: ['./events-item-box.component.scss']
})
export class EventsItemBoxComponent implements OnInit {

  @Input() product: Product;
  @Input() event: EventSummary;
  @Input() currency: any = this.productService.Currency;
  @Input() cartModal: boolean = false; // Default False

  public eventDate: string;

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  constructor(private productService: ProductService,
    private datePipe: DatePipe,
    private router: Router) {

  }

  ngOnInit(): void {
  }

  formatDate(eventDate: string): string {
    const formatDate = new Date(eventDate);
    return this.datePipe.transform(formatDate, 'd MMM y');
  }

  formatTimeRange(startTime: string, endTime: string): string {
    return `${startTime} - ${endTime}`;
  }

  navigateWithCustomData(eventSummary: any) {
    const navigationExtras: NavigationExtras = {
      state: { data: eventSummary }
    };

    this.router.navigate(['/home/vendor/event/details'], navigationExtras)
      .catch(error => console.log('Navigation error:', error));
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }
}

