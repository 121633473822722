import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';

import { ClientService } from '@app/core/services';
import { environment } from '@env/environment';


export class Stomp extends InjectableRxStompConfig {

  public heartbeatIncoming = 0;
  public heartbeatOutgoing = 20000; 
  public reconnectDelay = 5000;
  public connectHeaders = {
    login: '',
    passcode: '',
    host: ''
  };

  constructor(public _clientService: ClientService) {
    super();
  }

  public debug = (msg: string): void => {
    if (environment.debug === true) {
      console.log(msg);
    }
  }

  public beforeConnect = (client: any): Promise<void> => {
    let that = this;
    return new Promise<void>((resolve, reject) => {
      that._clientService.clientSubject.subscribe(data => {
        if (data == null) {
          return;
        } 
        client.stompClient.brokerURL = data.queueConfig.brokerURL;
        that.brokerURL = data.queueConfig.brokerURL;
        that.connectHeaders.login = data.queueConfig.user;
        that.connectHeaders.passcode = data.queueConfig.token;
        that.connectHeaders.host = "/";//data.queueConfig.host;
        that._clientService.cleanupConfigData();        
        resolve();
      })
    });
  }

  
}
