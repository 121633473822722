import { Component, OnInit } from '@angular/core';
import { StoreService } from '@app/core/services';

@Component({
  selector: 'app-accept-cookie',
  templateUrl: './accept-cookie.component.html',
  styleUrls: ['./accept-cookie.component.scss']
})
export class AcceptCookieComponent implements OnInit {
  
  public acceptedCookie: boolean = false;

  constructor(private storeService : StoreService) {
   }

  ngOnInit() {
    this.acceptedCookie = this.storeService.acceptedCookie();
  }

  onAcceptCookie(){
    this.storeService.acceptCookie();
    this.acceptedCookie = this.storeService.acceptedCookie();
 }
}
