import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserModel } from '@app/core/models/user/user.model';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { SetPhoneStateAction, SetUserPhoneStep1Action, SetUserPhoneStep2Action } from 'src/@allxs/store/actions/user.action';
import { UserState } from 'src/@allxs/store/state/user.state';

@Component({
    selector     : 'allxs-user-phonenumber',
    templateUrl  : './phonenumber.component.html',
    styleUrls    : ['./phonenumber.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PhoneNumberComponent implements OnChanges, OnInit, OnDestroy
{

    @Input("userdata") userdata: UserModel;
    @Select(UserState.currentUser) user$: Observable<UserModel>;
    @Select(UserState.phoneState) phoneState$: Observable<string>;

    public phoneState: string = "UNKNOWN"
    form: UntypedFormGroup;
    phoneForm: UntypedFormGroup;
    
    private subscription: Subscription = new Subscription();
    
    /**
     * Constructor
     */
    constructor(private _store: Store,
        private _formBuilder: UntypedFormBuilder)
    {
        this.form = this._formBuilder.group({
            otp: ['', [Validators.required, Validators.minLength(6)]]
          })
          this.phoneForm = this._formBuilder.group({
            userPhone: ['', [Validators.required, Validators.minLength(10)]]
          })
    }

    
    ngOnInit(): void {
        this.subscription.add(this.phoneState$.subscribe(data => { 
            this.phoneState = data;
         }))
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void
    {
        // Expanded
    
    }

    onChange(){
        this._store.dispatch(new SetPhoneStateAction("CHANGE"))
    }

    onSubmitNewPhone(){
        let phoneno = this.phoneForm.value["userPhone"] as string;
        if (phoneno.startsWith("0"))
           phoneno = "+27" + phoneno.substring(1, phoneno.length);
        this._store.dispatch(new SetUserPhoneStep1Action(phoneno))
    }

    submitOTP(){
        this._store.dispatch(new SetUserPhoneStep2Action(this.form.value["otp"]))
    }
}
