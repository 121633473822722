<div *ngIf="phoneState =='VERIFIED'">{{userdata.phoneNumber}} <button (click)="onChange()">Change</button></div>
<div *ngIf="phoneState =='CHANGE'">
  <Form [formGroup]="phoneForm">
    <div class="row">
      <div class="col-md-8">
        <input type="tel" class="form-control" formControlName="userPhone" name="userPhone" placeholder="Phone Number">
      </div>
      <div class="col-md-2">
        <button (click)="onSubmitNewPhone()" [disabled]="phoneForm.invalid">Update</button>
      </div>
    </div>
  </Form>
</div>
<div *ngIf="phoneState =='OTP'">
  <div class="row">
    <div class="col-md-2">
      Enter OTP:
    </div>
    <div class="col-md-8">

      <form [formGroup]="form">
        <div class="outer-otp">
          <div id="otp" class="inner-otp">
            <input id="otpInput" type="text" formControlName="otp" maxlength="6"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              onKeyPress="if(this.value.length==6) return false;" appPrefixFocusAndSelect />
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-2">
      <button class="btn btn-success" [disabled]="form.disabled || form.invalid" (click)="submitOTP()">
        <span>
          Submit
        </span>
      </button>
    </div>
  </div>
</div>