import { RegistrationModel } from "@app/core/models/account/registration.model";
import { UserModel } from "@app/core/models/user/user.model";


export class SetClientAction {
    static readonly type = '[Auth] SetClientAction';
    constructor(public clientId : number) {}
 }

 export class GoogleLogin {
   static readonly type = '[Auth] LoginGoogle';
   constructor() {}
}

 
export class LoginSuccess {
    static readonly type = '[Auth] LoginSuccess';
    constructor(public token : string, public data : any, public resultUrl: string = '/') {}
 }
  

export class Logout {
    static readonly type = '[Auth] Logout';
    constructor(public reason : string) {}
}

export class UpdateRegAction {
    static readonly type = '[Auth] update Reg';
    constructor(public userData: UserModel) {}
 }

 export class AccountRegistered {
    static readonly type = '[Auth] registered';
    constructor(public registrationData: RegistrationModel) {}
 }
 

 export class SwitchAccount{
    static readonly type = '[Auth] switchAccount';
    constructor(public userId: string) {}
 }

 export class SwitchToMainAccount{
   static readonly type = '[Auth] switchMainAccount';
   constructor() {}
}
 
export class SkipStep{
   static readonly type = '[Auth] Skip step';
   constructor(public step: string) {}
}
 
export class NextStep{
   static readonly type = '[Auth] Next Step';
   constructor() {}
}
 