import { Component, Input, OnInit } from '@angular/core';
import { VendorStoreModel } from '@app/core/models/vendor/vendor-store.model';
import { Images, Product } from '@app/shared/classes/product';
import { NewProductSlider } from '@app/shared/data/slider';
import { ProductService } from '@app/shared/services/product.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-item-box-vertical-slider',
  templateUrl: './item-box-vertical-slider.component.html',
  styleUrls: ['./item-box-vertical-slider.component.scss']
})
export class ItemBoxVerticalSliderComponent implements OnInit {

  @Input() title: string = 'More Products'; // Default
  @Input() type: string = 'fashion'; // Default Fashion
  @Input() moreProducts: Product[] = [];

  public products : Product[] = [];

  public NewProductSliderConfig: any = NewProductSlider;

  constructor(public productService: ProductService) { 
  }

  ngOnInit(): void {
  }

}