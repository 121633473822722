import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ClientModel } from '@app/core/models/client/client.model';
import { UserModel } from '@app/core/models/user/user.model';
import { isLoggedInSelector } from '@app/core/store/auth/selector';
import { AppStateInterface } from '@app/core/types/appState.interface';
import { NavService } from '@app/shared/services/nav.service';
import { Store, select } from '@ngrx/store';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AuthState } from 'src/@allxs/store/state/auth.state';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, AfterViewInit {

  public balance: number = 0;
  public rewardsBalance: number = 0;
  public firstName: string = "";
  public lastName: string = "";
  public accountNumber: number = 0;
  public userPhoto: string;
  private userData: UserModel;
  public pinDialogVisibility: boolean = false;
  public myAngularxQrCode: string = "";
  public progress: string = "normal";
  public targetElement: HTMLElement;
  public accountOTPDialogVisible: boolean = false;
  public countDown: number = 0;
  public accountOTPDialogWidth: string = "800px";
  private source: Observable<any>;
  private timeLeft: string = "00:00:00";
  private updateTimerSubscription: Subscription;
  private updateTimer: any = null;
  public mode: string = "wallet";
  public whatsApp: string|null = null;

  public right_side_bar: boolean;
  public layoutType: string = 'RTL';
  public layoutClass: boolean = false;

  private subscription: Subscription;
  private countdownSubscription: Subscription;
  public getScreenWidth: any;
  public getScreenHeight: any;
  private clientData: ClientModel;
  public topupForm: FormGroup;
  public topupAmountForm: FormGroup;
  private topupFee: number = 0;

  public isLoggedIn: boolean = false;

  @Select(AuthState.isLoggedIn) isLoggedIn$: Observable<boolean>;

  constructor(public navServices: NavService, private store: Store<AppStateInterface>,
    private changeRef: ChangeDetectorRef) {
    this.subscription = new Subscription();
    this.subscription.add(this.isLoggedIn$.subscribe(data => {
      this.isLoggedIn = data;
   }));


   // this.customizeThemeColor('#000000');
   }

ngAfterViewInit(): void {
  this.changeRef.detectChanges();
}

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public rightSidebar($event) {
    this.right_side_bar = $event
  }

  public clickRtl(val) {
    if (val === 'RTL') {
      document.body.className = 'rtl';
      this.layoutClass = true;
      this.layoutType = 'LTR';
    } else {
      document.body.className = '';
      this.layoutClass = false;
      this.layoutType = 'RTL';
    }
  }
ngOnInit(): void {
}

customizeThemeColor(colour: string) {
 /// document.documentElement.style.setProperty('--theme-deafult', colour);
  document.documentElement.style.setProperty('--primary-colour', colour);
}

}

