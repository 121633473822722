import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventSummary } from '@app/shared/classes/event';

@Component({
  selector: 'app-pagination-events',
  templateUrl: './pagination-events.component.html',
  styleUrls: ['./pagination-events.component.scss']
})
export class PaginationEventsComponent implements OnInit {

  @Input() events: EventSummary[] = [];
  @Input() paginate: any = {};

  @Output() setPage  : EventEmitter<any> = new EventEmitter<any>();
    
  constructor() { 
    
  }

  ngOnInit(): void {
  }

  pageSet(page: number) {
    this.setPage.emit(page);  // Set Page Number  
  }
}

