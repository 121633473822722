<app-task-card class="vw-100 bg-info bg-gradient" >
    <div class="card" style="width: 100%; min-width: 100%; background-color: #94162a; border-style: solid; border-color: #94162a; border-width: 1px;">
      <div class="card-header">
        <h4>{{ task?.taskName }}</h4>
    </div>
    <div class="card-body">
        <div class="d-flex align-items-start">
            <div class="media-body">
                <span>Vendor Name</span>
                <h2 class="mb-0">{{ task?.amount | currency:'R'}}</h2>
                <h5 class="f-w-600">Due Date: {{ getTaskDeadline(task.deadline) }}</h5>
                <p>{{ task?.taskDescription }} </p>
            </div>
        </div>
        <br />
    </div>
   </div> 
 </app-task-card>