import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { POMenuOrderModel } from '@app/core/models/preorder-cart/pomenuorder.model';
import { PreorderCartOrderModel } from '@app/core/models/preorder-cart/preordercartorder.model';
import { MenuModel } from '@app/core/models/preorder/menu.model';
import { Product } from '@app/shared/classes/product';
import { ProductService } from '@app/shared/services/product.service';
import { Select, Store as ngxStore } from '@ngxs/store';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { SelectedPreorderModel } from 'src/@allxs/model/preorder/selected_preorder.model';
import { SelectPreOrderMenuAction } from 'src/@allxs/store/actions/preorder.action';
import { PreOrderState } from 'src/@allxs/store/state/preorder.state';
import * as moment from 'moment';
import { PreorderModel } from '@app/core/models/preorder/preorder.model';


@Component({
  selector: 'app-menu-item-box',
  templateUrl: './menu-item-box.component.html',
  styleUrls: ['./menu-item-box.component.scss']
})
export class MenuItemBoxComponent implements OnInit, OnDestroy {


  public _menu: MenuModel;
  @Input() set menu(value: MenuModel) {
    this._menu = value;
  }


  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() userId: string;
  private subscription: Subscription = new Subscription();

  public selected: boolean = false;
  private selectedItem: SelectedPreorderModel;
  private cartData: POMenuOrderModel[] = [];
  private cartOrderData: PreorderModel[] = [];
  public ImageSrc: string = "assets/images/preorders/menu.jpg";
  public itemCount: number = 0;
  public orderItemCount: number = 0;
  @Select(PreOrderState.selectPreOrder) selectedOrder$: Observable<SelectedPreorderModel>;
  @Select(PreOrderState.selectPreOrderCart) preOrderCart$: Observable<PreorderCartOrderModel>;
  @Select(PreOrderState.selectPreOrderMenuOrder) selectedPreOrderCart$: Observable<POMenuOrderModel[]>;
  @Select(PreOrderState.selectedUserOrders) selectedUsersOrder$: Observable<PreorderModel[]>;

  constructor(private productService: ProductService,
    private _ngxStore: ngxStore,) { }

  selectMenu(menu) {
    this._ngxStore.dispatch(new SelectPreOrderMenuAction(this._menu));
  }

  ngOnInit(): void {

    this.subscription.add(this.selectedOrder$.subscribe((preOrder) => {
      this.selectedItem = preOrder;
      this.selected = this._menu.menuId == preOrder.menuId;
      this.itemCount = this.getMenuCounts(this.cartData);
      this.orderItemCount = this. getUserMenuCounts(this.cartOrderData);
    }));

    this.subscription.add(this.selectedPreOrderCart$.subscribe((data) => {
      if (data) {
        this.cartData = data;
        this.itemCount = this.getMenuCounts(data);
      }
    }));

    this.subscription.add(this.selectedUsersOrder$.subscribe(data => {
      if (data){
        this.cartOrderData = data;
        this.orderItemCount = this. getUserMenuCounts(data);
      }
  }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getMenuCounts(data: POMenuOrderModel[]): number {
    var count = 0;
    data.forEach((item) => {
      item.days.forEach((day) => {
        if (moment(day.date).format('DD-MMMM-YYYY') == this.selectedItem.dateId) {
          day.forUser.forEach((user) => {
            if (user.userId == this.selectedItem.userId) {
              if (user.cart.menuId == this._menu.menuId) {
                count += user.cart.totalItemCount;
              }
            }
          });
        }
      });
    });
    return count;
  }

  private getUserMenuCounts(data: PreorderModel[]): number {
    var count = 0; 
    
    if (data.length == 0) return count;

    data.forEach((item) => {
      if (item.menuId == this._menu.menuId && moment(item.orderDate).format('DD-MMMM-YYYY') == this.selectedItem.dateId) {
        count += item.orderItems;
      }
      
    });
    return count;
  }
}

