import { Component, EventEmitter, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { VendorOrderResponseModel } from '@app/core/models/cart/vendor-order-response.model';
import { ClientService, DataService, StoreService } from '@app/core/services';
import { ProductService } from '@app/shared/services/product.service';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { List } from 'immutable';
import { PeachPaymentComponent } from '../peachpayment/peachpayment.component';

@Component({
  selector: 'app-peach-modal',
  templateUrl: './peach-modal.component.html',
  styleUrls: ['./peach-modal.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class PeachModalComponent {

  public active = 'select';
  public closeResult: string;
  public modalOpen: boolean = false;
  public spinner
  modal: NgbModalRef;

  public vendorOrderResponse: List<VendorOrderResponseModel>;
  progressMessage: string = "";
  public orderNumber: number = 0;
  public peachPayments : PeachPaymentComponent;

  
  @ViewChild("peachModal", { static: false }) PeachModal: TemplateRef<string>;
  @Output() peachSuccess: EventEmitter<any> = new EventEmitter();
  @Output() peachFail: EventEmitter<any> = new EventEmitter();
  @Output() peachReady: EventEmitter<any> = new EventEmitter();

  private orderId: any;
  private entityId: any;
  private requestId: any;

  constructor(
    private storeService: StoreService,
    public clientService: ClientService,
    private dataService: DataService,
    public productService: ProductService,
    private modalService: NgbModal) {
  }

  async openModal(orderId: any, entityId: any, requestId: any) {
    this.modalOpen = true;
    this.orderId = orderId;
    this.entityId = entityId;
    this.requestId = requestId;
    
    this.modal = this.modalService.open(this.PeachModal, {
      ariaLabelledBy: 'Peach-Modal',
      centered: true,
      size: 'paygatesize',
      windowClass: 'paygate-modal',
      backdrop: 'static'
    });

    this.modal.result.then((result) => {
      `Result ${result}`
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    
  }

  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }


  onFail(result: any) {
    this.peachFail.emit(result);
    this.modal.dismiss('close');
  }

  onPeachInit(event) {
    this.peachPayments = event;
    this.peachPayments.startPayment(this.orderId, this.entityId, this.requestId);
    this.peachReady.emit(event);
  }


  onPeachComplete(event) {
    this.modal.dismiss('close');
    this.peachSuccess.emit(event);    
  }


  onPeachCancelled(event) {
    this.peachFail.emit(event);
    this.modal.dismiss('close');
  }

  onPeachFailed(event){
    this.peachFail.emit(event);
    this.modal.dismiss('close');
  }
}
