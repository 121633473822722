import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";
import { BehaviorSubject, Observable, map } from "rxjs";

import { config } from "../app.config";
import { ClientModel } from "../models/client/client.model";

import { PortalModel } from "../models/client/portal.model";
import { ClientStoreModel } from "../models/client/clientstore.model";
import { WebOrderModel } from "../models/weborder/weborder.model";
import { OrderResponseModel } from "../models/cart/order-response.model";
import { ClientTopupModel } from "../models/client/clienttopup.model";
import { ClientTopupResponseModel } from "../models/client/clienttopupresponse.model";
import { LocationModel } from "../models/location/location.model";
import { LocationLookupModel } from "../models/location/location-lookup.model";
import { TicketResponseModel } from "../models/ticket/ticket-response.model";
import { ThemeImage } from "../models/client/themeimage.model";
import { environment } from "@env/environment";
import { VendorStoreModel } from "../models/vendor/vendor-store.model";
import { ClientVendorModel } from "../models/client/client-vendor.model";
import { WebTaskOrderModel } from "src/@allxs/model/task/weborder.model";
import { ClaimStateModel } from "src/@allxs/model/auth/claim-state.model";
import { ResetPasswordModel } from "src/@allxs/model/auth/reset-password.model";
import { UserOTPUpdateModel } from "../models/user/userotp-request.model";
import { Store } from "@ngrx/store";
import { AppStateInterface } from "../types/appState.interface";
import * as  AuthActions from '@app/core/store/auth/actions';
import { Store as ngxStore } from '@ngxs/store';
import { SetClientAction } from 'src/@allxs/store/actions/auth.action';
import { User_SetClientAction } from "src/@allxs/store/actions/user.action";


@Injectable()
export class ClientService {

  initialClientModel = new ClientModel();
  public currentClient: ClientModel;
  public currentClientStore: ClientStoreModel;
  public clientSubject = new BehaviorSubject<ClientModel | null>(null);

  constructor(
    @Inject(DOCUMENT) private document: any,
    private http: HttpClient,
    private store: Store<AppStateInterface>,
    private ngxStore: ngxStore) {
  }


  public getClientByLocalURI(): Observable<ClientStoreModel> {
     if (environment.portalUrl !== null) {
       return this.getClientbyURI(environment.portalUrl);
     } else {
       return this.getClientbyURI(this.document.location.hostname)
     }
  }

  public getClientbyURI(uri: string): Observable<ClientStoreModel> {
    return new Observable(subscriber => {
      let that = this;
      var url = config.TRIOSAPI + '/api/client/getclientstore?uri=' + uri + "&includeProducts=true";
      this.http.get<ClientStoreModel>(url)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: ClientStoreModel) => {
            if ((data == null) || (data == undefined))
              return;
            try {

              this.ngxStore.dispatch(new SetClientAction(data.clientData.clientId));
              this.ngxStore.dispatch(new User_SetClientAction(data.clientData.clientId));
              
              const bannersToFilter = data.clientData.theme.Images;
              
              let typeToFilter = 'Theme Logo';
              var clientLogo = this.getBannersByTypeWithNames(bannersToFilter, typeToFilter)[0].Banner?.Path ?? '';
              if (clientLogo) {
                data.clientData.clientLogo = clientLogo;
              }

              if (data.vendors.length === 1 && data.clientData.startPage.toLowerCase() !== 'dashboard' && (data.clientData.vendorId == null || data.clientData.vendorId == 0)) {
                let vendor = data.vendors[0];

                const bannersToFilter = vendor.vendorData.theme.Images;

                let typeToFilter = 'Vendor Logo';
                let vendorLogo = this.getBannersByTypeWithNames(bannersToFilter, typeToFilter)[0]?.Banner?.Path ?? '';
                if (vendorLogo && vendorLogo.trim() !== '') {
                  data.clientData.clientLogo = vendorLogo.trim();
                }
              }
              else if (data.clientData.vendorId != null && data.clientData.vendorId != 0) {
                data.clientData.startPage = "vendorstore";
              }

              if (data.clientData.versions.uiVersion == null || data.clientData.versions.uiVersion == '') {
                if (data.clientData.productionVersion.trim() != config.version.trim()) {
                  // DialogUtility.alert({
                  //   title: 'New App Version Available!',
                  //   content: "Please click  <mark text-danger>OK</mark> to update the application.<br/><br/>" +
                  //     "If this fails please close the browser and reopen it to load the latest version.<br/><br/>" +
                  //     "<small class='text-muted'>" + config.version + " <i class='fa fa-long-arrow-right'></i> " + data.productionVersion + "</small>",
                  //   okButton: { text: 'OK', click: () => { window.location.reload(); } },
                  //   showCloseIcon: true,
                  //   closeOnEscape: true,
                  //   animationSettings: { effect: 'Zoom' }
                  // });
                }
              }
              else {
                if (data.clientData.versions.uiVersion.trim() != config.version.trim()) {
                  // DialogUtility.alert({
                  //   title: 'New App Version Available!!',
                  //   content: "Please click  <mark text-danger>OK</mark> to update the application.<br/><br/>" +
                  //     "If this fails please close the browser and reopen it to load the latest version.<br/><br/>" +
                  //     "<small class='text-muted'>" + config.version + " <i class='fa fa-long-arrow-right'></i> " + data.versions.uiVersion + "</small>",
                  //   okButton: { text: 'OK', click: () => { window.location.reload(); } },
                  //   showCloseIcon: true,
                  //   closeOnEscape: true,
                  //   animationSettings: { effect: 'Zoom' }
                  // });
                }
              }
            }
            catch (e) {

            }
            config.siteURL = 'assets/imgages/favicon/favicon.ico';
            that.currentClientStore = data;
            that.currentClient = that.currentClientStore.clientData;
            //   if ((data.analyticsCode == null) || (data.analyticsCode == undefined) || (data.analyticsCode == '')) {
            //     that.appendGaTrackingCode(environment.googleAnalyticsCode);
            //   }
            //   else {
            //     //that.appendGaTrackingCode("UA-7458436-6");
            //     that.appendGaTrackingCode(data.analyticsCode);
            //   }
            if (that.currentClient.clientLogo == null)
              that.currentClient.clientLogo = "assets/img/logo.png";
            if (that.currentClient.portal == null) {
              that.currentClient.portal = new PortalModel();
            }
            that.clientSubject.next(that.currentClient);
            subscriber.next(that.currentClientStore);
            const link: any = document.querySelector(`link[rel*='icon']`) || document.createElement('link')
            link.type = 'image/x-icon'
            link.rel = 'shortcut icon'

            if (1 == 1) {
              link.href = that.currentClient.clientLogo || 'base64'
            } else {
              link.href = 'url' || 'base64'
            }
            document.getElementsByTagName('head')[0].appendChild(link)
          },
          error: (errorResponse: any) => {
            this.clientSubject.error(errorResponse);
          }

        }
        );
    });
  }
  public cleanupConfigData() {
    this.currentClient.queueConfig = null;
  }

  
  getBannersByTypeWithNames(
    banners: ThemeImage[],
    type: string
  ): { BannerName: string; Banner: ThemeImage }[] {
    const filteredByType = banners.filter(banner => banner.Type.toLowerCase() === type.toLowerCase());

    const limitedBanners = filteredByType.slice(0, 3);

    return limitedBanners.map(({ Name, ...rest }) => ({
      BannerName: Name,
      Banner: { Name, ...rest },
    }));
  }

  public postClientTopup(topupData: ClientTopupModel) {
    return new Observable<ClientTopupResponseModel>(subscriber => {
      this.http.post<ClientTopupResponseModel>(config.TRIOSAPI + '/api/clientBanking/topupAccount', topupData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe({
          next: (data: any) => {
            subscriber.next(data);
          },
          error: (response: any) => {
            subscriber.error(response);
          }
        }
        );
    });
  }

  public postClientStartPayment(topupData: ClientTopupModel) {
    return new Observable<ClientTopupResponseModel>(subscriber => {
      this.http.post<ClientTopupResponseModel>(config.TRIOSAPI + '/api/clientBanking/startpayment', topupData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getClientTopupStatus(depositId: string) {
    return new Observable<any>(subscriber => {
      this.http.get<any>(config.TRIOSAPI + '/api/clientBanking/topupAccountStatus?depositId=' + depositId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe({
          next: (data: any) => {
            subscriber.next(data);
          },
          error: (response: any) => {
            subscriber.error(response);
          }
        }
        );
    });
  }

  public placeWebOrder(clientId: number, webOrder: WebOrderModel, au: boolean): Observable<OrderResponseModel> {
    return new Observable<OrderResponseModel>(subscriber => {
      var url = config.TRIOSAPI + '/api/client/placeWebOrder?clientId=' + clientId;
      if (au)
        url = config.TRIOSAPI + '/api/client/placeAuthWebOrder?clientId=' + clientId
      this.http.post<OrderResponseModel>(url, webOrder)
        .pipe(
          map(response => {
            // response.forEach(element => {
            //   element.startDate = moment(moment.utc(element.startDate).toDate()).format("DD MMM YYYY HH:mm")
            //   element.endDate = moment(moment.utc(element.endDate).toDate()).format("DD MMM YYYY HH:mm")
            // });
            return response;
          })
        )
        .subscribe({
          next: (data: any) => {
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }
        }
        )
    });
  }

  public placeWebCardOrder(clientId: number, webOrder: WebOrderModel, src : string): Observable<any> {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/placeWebCardOrder?clientId=' + clientId , webOrder)
        .pipe(
          map(response => {
            // response.forEach(element => {
            //   element.startDate = moment(moment.utc(element.startDate).toDate()).format("DD MMM YYYY HH:mm")
            //   element.endDate = moment(moment.utc(element.endDate).toDate()).format("DD MMM YYYY HH:mm")
            // });
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        )
    });
  }

  public lookupLocation(data: LocationLookupModel) {
    return new Observable<Array<LocationModel>>(subscriber => {
      this.http.post<Array<LocationModel>>(config.TRIOSAPI + '/api/client/lookuplocation', data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public getClientLocations(search = "") {
    return new Observable<Array<LocationModel>>(subscriber => {
      this.http.get<Array<LocationModel>>(config.TRIOSAPI + '/api/client/getlocations?clientId=' + this.currentClient.clientId + '&search=' + search)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            alert('Got Location error');
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public getUserTickets() {
    return new Observable<Array<TicketResponseModel>>(subscriber => {
      this.http.get<Array<TicketResponseModel>>(config.TRIOSAPI + '/api/client/getuserTickets?clientId=' + this.currentClient.clientId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            alert('Got Location error');
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public getClientVendors(clientId: number, search: string = "") {
    return new Observable<Array<ClientVendorModel>>(subscriber => {
      this.http.get<Array<ClientVendorModel>>(config.TRIOSAPI + '/api/client/getclientvendors?clientId=' + clientId + '&search=' + search)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            
            this.store.dispatch(AuthActions.logoutUser());
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public placeWebTaskOrder(clientId: number, webTaskOrder: WebTaskOrderModel): Observable<OrderResponseModel> {
    return new Observable<OrderResponseModel>(subscriber => {
      var url = config.TRIOSAPI + '/api/client/placeWebTaskOrder?clientId=' + clientId;
      this.http.post<OrderResponseModel>(url, webTaskOrder)
        .pipe(
          map(response => {
            // response.forEach(element => {
            //   element.startDate = moment(moment.utc(element.startDate).toDate()).format("DD MMM YYYY HH:mm")
            //   element.endDate = moment(moment.utc(element.endDate).toDate()).format("DD MMM YYYY HH:mm")
            // });
            return response;
          })
        )
        .subscribe({
          next: (data: any) => {
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }
        }
        )
    });
  }
  
  
  public placeWebTaskCardOrder(clientId: number, webOrder: WebTaskOrderModel): Observable<any> {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/placewebcardtaskorder?clientId=' + clientId, webOrder)
        .pipe(
          map(response => {
            // response.forEach(element => {
            //   element.startDate = moment(moment.utc(element.startDate).toDate()).format("DD MMM YYYY HH:mm")
            //   element.endDate = moment(moment.utc(element.endDate).toDate()).format("DD MMM YYYY HH:mm")
            // });
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        )
    });
  }

  getPasswordOTP(clientId: number, emailAddress: string) {
    return new Observable<ClaimStateModel>(subscriber => {
      this.http.get<ClaimStateModel>(config.TRIOSAPI + '/api/client/getpasswordOTP?clientId=' + clientId + '&emailAddress=' + emailAddress)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  getPhoneOTP(clientId: number, phoneNumber: string) {
    return new Observable<UserOTPUpdateModel>(subscriber => {
      this.http.get<UserOTPUpdateModel>(config.TRIOSAPI + '/api/client/getphoneOTP?clientId=' + clientId + '&phoneNumber=' + phoneNumber)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  resetPasswordOTP(clientId: number, claimData: ResetPasswordModel) {
    return new Observable<ClaimStateModel>(subscriber => {
      this.http.post<ClaimStateModel>(config.TRIOSAPI + '/api/account/resetpasswordotp?clientId=' + clientId, claimData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  checkEmailClaim(clientId: number, emailAddress: string) {
    return new Observable<ClaimStateModel>(subscriber => {
      this.http.get<ClaimStateModel>(config.TRIOSAPI + '/api/client/checkemailclaim?clientId=' + clientId + '&emailAddress=' + emailAddress)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  setEmailClaim(clientId: number, claimData: ResetPasswordModel) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/setemailclaim?clientId=' + clientId, claimData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }
}
