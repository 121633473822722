import { createAction, props } from "@ngrx/store";
import { AuthInterface } from "../../types/auth/auth-interface.interface";


export const getAuth = createAction('[Auth] Get Auth');
export const getAuthState = createAction('[Auth] Get Auth State ', props<{isAuthLoggedIn: boolean }>());
export const loginUser = createAction('[Auth] Login', props<{clientId: number, userName: string, password: string; resultUrl: string}>());
export const logoutUser = createAction('[Auth] Logout');
export const logoutSuccess = createAction('[Auth] Logout Success');
export const loginFail = createAction('[Auth] Login fail', props<{data : string}>());
export const loginSuccess = createAction('[Auth] Login success', props<{data: AuthInterface}>());
export const requestPhone = createAction('[Auth] RequetPhone', props<{data: AuthInterface, requestPhone: boolean }>());
