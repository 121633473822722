import { Component, Input, OnInit } from '@angular/core';
import { config } from '@app/core/app.config';
import { AppStateInterface } from '@app/core/types/appState.interface';
import { clientSelector } from '@app/core/store/client/selectors';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = '';// Default Logo
  @Input() newsletter: boolean = true; // Default True
  @Input() companyName: string;// = 'Allxs Media Pty Ltd.';
  @Input() contactUsEmail: string;// = 'support@allxsmedia.com';
  @Input() footerDescription: string = `Allxs Media Pty Ltd is a leading Fintech company that specializes in providing Africa's top-notch experience management platform.`;
  @Input() whatsAppContact: string;// = '+27(0) 66 472 8657'

  public today: number = Date.now();
  public version: string = '';
  private subscription: Subscription = new Subscription();
  public clientData: any;
  constructor(private store: Store<AppStateInterface>,) { }

  ngOnInit(): void {
    this.subscription.add(this.store.pipe(select(clientSelector)).subscribe(data => {
      if (data) {
        if (data == null) {
          return;
        }
        this.clientData = data.clientData;
        this.companyName = this.clientData.clientName;
        this.contactUsEmail = this.clientData.fromEmail;
        this.whatsAppContact = this.clientData.whatsappNumber;
      }
    }));
    this.version = config.version;
  }

}