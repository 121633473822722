import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Subscription, Observable, map } from 'rxjs';
import * as moment from 'moment';
import { config } from "../app.config";
import { CollectionOrderModel } from "../models/order/collection-order.model";

@Injectable()
export class TransactionService implements OnDestroy {

    private subscription: Subscription;

    constructor(private http: HttpClient,) {
        this.subscription = new Subscription();

    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public getStatementofBalance(accountId: string, fromDate: string, toDate: string) {
        var urlString = "/api/users/loadStatement?accountId=" + accountId + "&fromDate=" + fromDate + "&toDate=" + toDate + "&includeArchive=false";
        return new Observable<any>(subscriber => {
          this.http.get<any>(config.TRIOSAPI + urlString)
            .pipe(
              map(data => {
                data.transactionLine.forEach(item => {
                  item.transactionDate = moment(moment.utc(item.transactionDate).toDate()).format("DD MMM YYYY HH:mm");
                });
                return data;
              })
            )
            .subscribe(
              data => {
                subscriber.next(data);
              },
              response => {
                subscriber.error(response);
              }
            );
        });
      }

      public getCollections(userId: string): Observable<Array<CollectionOrderModel>> {
        let that = this;
        return new Observable<Array<CollectionOrderModel>>(subscriber => {
          this.http.get<Array<CollectionOrderModel>>(config.TRIOSAPI + '/api/collections/getcollections?userId=' + userId)
            .pipe(
              map(response => {
                response.forEach(item => {
                  item.orderDate = moment(moment.utc(item.orderDate).toDate()).format("DD MMM YYYY HH:mm")
                });
                return response;
              })
            )
            .subscribe(
              data => {
                subscriber.next(data);
              },
              response => {
                subscriber.error(response);
              }
            );
        });
      }

      public loadOrders(accountId: string, fromDate: string, toDate: string) {
        var urlString = "/api/users/loadOrders?accountId=" + accountId + "&fromDate=" + fromDate + "&toDate=" + toDate;
        return new Observable<any>(subscriber => {
          this.http.get<any>(config.TRIOSAPI + urlString)
            .pipe(
              map(data => {
                data.orderLine.forEach(item => {
                  item.orderDate = moment(moment.utc(item.orderDate).toDate()).format("DD MMM YYYY HH:mm")
                });
                return data;
              })
            )
            .subscribe(
              data => {
                subscriber.next(data);
              },
              response => {
                subscriber.error(response);
              }
            );
        });
      }
}