import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ClientMerchantModel } from '@app/core/models/client/clientmerchant.model';
import { UserModel } from '@app/core/models/user/user.model';
import { ProductService } from '@app/shared/services/product.service';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EftModalComponent } from '../eft-modal/eft-modal.component';

@Component({
  selector: 'app-topup-modal',
  templateUrl: './topup-modal.component.html',
  styleUrls: ['./topup-modal.component.scss']
})
export class TopupModalComponent implements OnInit, OnDestroy {

  @ViewChild("topup", { static: false }) Topup: TemplateRef<any>;
  @ViewChild("eftModal") EFTModal: EftModalComponent;
  @Input() useEFT: boolean = false;
  @Output() onTopupAccount = new EventEmitter<void>();
  @Output() onTopupCancel = new EventEmitter<void>();

  @Input() merchantModel: ClientMerchantModel;
  @Input() linkedAccounts: UserModel[];

  public closeResult: string;
  public modalOpen: boolean = false;
  public currency: any = this.productService.Currency;
  @Input() topupFee: number;
  modal: NgbModalRef;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private productService: ProductService,
    private modalService: NgbModal) { }

  ngOnInit(): void {

  }

  onAcceptClick() {
    this.modal.dismiss('close');
    this.onTopupAccount.emit();
  }

  onEFTClick() {
    this.modal.dismiss('close');
    this.EFTModal.openModal(this.merchantModel, this.linkedAccounts);
  }

  onDeclineClick() {
    this.modal.dismiss('close');
    this.onTopupCancel.emit();
  }

  async openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 

      this.modal = this.modalService.open(this.Topup, {
        ariaLabelledBy: 'topup-modal',
        centered: true,
        size: 'md',
        windowClass: 'Top'
      });

      this.modal.result.then((result) => {
        `Result ${result}`
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });

    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
      this.onTopupCancel.emit();
    }
  }

}
