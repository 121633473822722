import { ProductCategoryListModel } from '../product/product-categorylist.model';
import { ProductModel } from '../product/product.model';
import { VendorModel } from './vendor.model';

export class VendorStoreModel {   
    public vendorId: number;
    public vendorData: VendorModel;
    public vendorName: string;
    public availableProducts: number;
    public page: number;
    public pageSize: number;
    public featuredProducts: number;
    public featuredProductList: Array<ProductModel>;
    public vendorCategories: Array<ProductCategoryListModel>;
    public vendorProducts: Array<ProductModel>;
    public deliveryProduct: Array<ProductModel>;
}

 