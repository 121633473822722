import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ClientModel } from '@app/core/models/client/client.model';
import { UserModel } from '@app/core/models/user/user.model';
import { StorageService, UserService } from '@app/core/services';
import { isLoggedInSelector } from '@app/core/store/auth/selector';
import { clientSelector } from '@app/core/store/client/selectors';
import { getUserSelector } from '@app/core/store/user/selectors';
import { AppStateInterface } from '@app/core/types/appState.interface';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Menu, NavService } from '../../services/nav.service';
import * as  AuthActions from '@app/core/store/auth/actions';
import { Store as ngxsStore, Select } from '@ngxs/store';
import { Logout, SwitchToMainAccount } from 'src/@allxs/store/actions/auth.action';
import { UserState } from 'src/@allxs/store/state/user.state';
import { AuthState } from 'src/@allxs/store/state/auth.state';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public themeLogo: string = 'assets/images/logos/default/logo.png'; // Default Logo

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;

  public balance: number = 0;
  public rewardsBalance: number = 0;
  public firstName: string = "";
  public lastName: string = "";
  public accountNumber: number = 0;
  public userPhoto: string;

  public isLoggedIn: boolean = false;
  private subscription: Subscription = new Subscription();
  private userData: UserModel;
  private clientData: ClientModel;
  private accountType: string;

  @Select(AuthState.accountType) accountType$: Observable<string>;
  @Select(AuthState.isLoggedIn) isLoggedIn$: Observable<boolean>;
  constructor(
    private router: Router,
    public navServices: NavService,
    private storageService: StorageService,
    public userService: UserService,
    private ngxsStore: ngxsStore,
    private store: Store<AppStateInterface>) {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url)
              this.setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
              if (subItems.path === event.url)
                this.setNavActive(subItems)
              if (!subItems.children) return false
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems)
              })
            })
          })
        }
      })
    })

    
    this.subscription.add(this.isLoggedIn$.subscribe(data => {
      this.isLoggedIn = data;
   }));

    this.store.pipe(select(clientSelector)).subscribe(data => {
      if (data) {
        this.clientData = data.clientData;
        this.themeLogo = this.clientData.clientLogo;
      }
    })

    this.subscription.add(
      this.store.pipe(select(getUserSelector)).subscribe(data => {
        if (data) {
          this.userData = data.userData!;
          this.accountNumber = data.userData?.userAccountNumber ?? 0;
          this.firstName = data.userData?.userDetail?.firstName ?? "";
          this.lastName = data.userData?.userDetail?.lastName ?? "";
          this.userPhoto = data.userData?.userPhoto ?? "";
        }
      }));

    this.subscription.add(this.accountType$.subscribe(data => {
      this.accountType = data;
      this.navServices.updateWalletMenuItems(this.accountType == 'child');
      
    }));

    //this.navServices.updateWalletMenuItems(this.isChildAccount());
  }

  // isChildAccount() {
  //   this.navServices.updateWalletMenuItems(this.accountType == 'child');
  //   return this.accountType == 'child';
  // }

  goToParentAccount() {
    this.ngxsStore.dispatch(new SwitchToMainAccount());
  }

  onLogout() {
    this.ngxsStore.dispatch(new Logout("user"));
    this.store.dispatch(AuthActions.logoutUser());
    this.router.navigate(['/home/auth/login']);
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active;

    if (item.title == "Leave Child Account") {
      this.goToParentAccount();
      this.navServices.updateWalletMenuItems(false);
    }

    if (item.title == "Log Out") {
      this.onLogout();
    }
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

}
