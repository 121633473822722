import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateInterface } from '@app/core/types/appState.interface';
import * as  AuthActions from '@app/core/store/auth/actions';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { clientSelector } from '@app/core/store/client/selectors';
import { Select } from '@ngxs/store';
import { AuthState } from 'src/@allxs/store/state/auth.state';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnDestroy {

  @Input() class: string;
  @Input() themeLogo: string; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  @Input() isVendorLanding: boolean = false;

  public stick: boolean = false;
  public loggedIn: boolean = false;
  private subscription: Subscription = new Subscription();

  @Select(AuthState.isLoggedIn) loggedInState$: Observable<boolean>;

  constructor(private router: Router, private store: Store<AppStateInterface>) {
    this.subscription = new Subscription();
    this.store.pipe(select(clientSelector)).subscribe(data => {
      if (data) {
        if (data.vendors.length === 1 &&
          data.clientData.startPage.toLowerCase() !== 'dashboard') {
          let vendor = data.vendors[0];
          if (vendor != null) {
            this.isVendorLanding = true;
          }
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(this.loggedInState$.subscribe(data => {
      this.loggedIn = data
    }));
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  onHome() {
    if (this.isVendorLanding) {
      this.router.navigate(['/home/vendor']);
    } else {
      this.router.navigate(['/home/landing']);
    }
  }

  onLogout() {
    this.store.dispatch(AuthActions.logoutUser());
    this.router.navigate(['./']);
  }

}