import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { VendorModel } from '@app/core/models/vendor/vendor.model';
import { Router } from '@angular/router';
import { GetVendorAction, SelectVendorAction } from '../actions/vendor.actions';
import { VendorService } from '@app/core/services';
import { VendorStoreModel } from '@app/core/models/vendor/vendor-store.model';
import { cloneDeep } from 'lodash-es';

export class VendorStateModel {
  public selectedVendor: VendorStoreModel;
  public deliveryProduct: any;
}

@State<VendorStateModel>({
  name: 'vendor',
  defaults: {
    selectedVendor: null,
    deliveryProduct: null
  }
})

@Injectable()
export class VendorState {

    constructor(private _vendorService: VendorService,
    private _router: Router){
  }

  @Selector()
  public static selectedVendor(state: VendorStateModel) {
    return state.selectedVendor;
  }
  
  @Selector()
  public static deliveryProduct(state: VendorStateModel) {
    return state.deliveryProduct;
  }

  @Action(SelectVendorAction)
  public selectVendorAction(ctx: StateContext<VendorStateModel>, action: SelectVendorAction) {
    var result =  cloneDeep(action.data);
    result.deliveryProduct = result.vendorProducts.filter(x => x.productName.includes("Delivery"));
    result.vendorProducts = result.vendorProducts.filter(x => !x.productName.includes("Delivery"));
    ctx.patchState({ selectedVendor: result, deliveryProduct: result.deliveryProduct});
    this._router.navigate(["home/vendor"]);
  }

  @Action(GetVendorAction)
  public getVendorAction(ctx: StateContext<VendorStateModel>, action: GetVendorAction) {
    return this._vendorService.getVendorById(action.vendorId).subscribe(data => {
      ctx.patchState({ selectedVendor: data});
      this._router.navigate(["home/vendor"]);
    })  
  }

}
