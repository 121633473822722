import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { config } from "../app.config";

import { VoucherModel } from "../models/voucher/voucher.model";
import { Observable, Subject, map } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class VoucherService {


    public voucherSubject = new Subject<Array<VoucherModel>>();


    constructor(
        private http: HttpClient,
        private toastr: ToastrService) {

    }


    public getUserTickets(userId: string) {
        let that = this;
        this.http.get<Array<VoucherModel>>(config.TRIOSAPI + '/api/voucher/gettickets?userId=' + userId)
            .pipe(
                map(response => {
                    return response;
                }
                ))
            .subscribe({
                next: (data: Array<VoucherModel>) => {
                    that.voucherSubject.next(data);
                },
                error: (response: any) => {
                    this.voucherSubject.error(response);
                }
            }
            );
    }


    public getTicket(ticketId: string)
    : Observable<Blob> {
    //   return this.http.get(`${this.basePath}/document/${id}`, {responseType: 'blob'});
    // }
   // return new Observable<any>(subscriber => {
      // this.http.get<any>(config.TRIOSAPI + '/api/ticket/getticketfile?id=' + ticketId)
      //   .subscribe((resultBlob: Blob) => {
      //     var downloadURL = URL.createObjectURL(resultBlob);
      //     window.open(downloadURL);
      //     },
      //     response => {
      //       subscriber.error(response);
      //     }
      //   );
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/pdf');
      return this.http.get(config.TRIOSAPI + '/api/ticket/getticketfile?id=' + ticketId, { headers: headers, responseType: 'blob' });
   // });
  }


}
