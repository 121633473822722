export const environment = {
  production: true,
  debug: false,
  title: "Allxs VIP",
  version: "1.0.0.107P",
  displayVersion: "2.0.5.0",
  systemVersion: '2.0.5.0',
  portalUrl: null,
  ccsandbox: false,
  log: {
      routing: false, 
      auth: false,
      store: false,
    }, 
    db: 'allxsdb',
    apiportal: 'https://clientapiprod.allxs.co.za',
    //apiportal: 'https://clientapidev.contextpos.com',
    nedbankAuthPage: 'https://portal.nedsecure.co.za/Lite/Authorise.aspx',
    GoogleMapAPIKey: "AIzaSyC196UkdTlV96SibaAIxos-D3VXAnC5KvA",
    firebase: {
      apiKey: "AIzaSyCru-p5FW_bitdrnPqjXAcgrjZpGLvPpGo",
      authDomain: "trios-1253.firebaseapp.com",
      databaseURL: "https://trios-1253.firebaseio.com",
      projectId: "trios-1253",
      storageBucket: "trios-1253.appspot.com",
      messagingSenderId: "674163863489",
      appId: "1:674163863489:web:49902b21d1d6aac4"
   },
 
}