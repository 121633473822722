import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IveriModalComponent } from "./components/iveri-modal/iveri-modal.component";
import { environment } from "@env/environment";
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule  } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { VendorState } from "./store/state/vendor.state";
import { PaygateModalComponent } from './components/paygate-modal/paygate-modal.component';
import { services } from "./services";
import { TaskState } from "./store/state/task.state";
import { TaskCardComponent } from "./components/taskcard/card.component";
import { TaskComponent } from "./components/task/task.component";
import { PaymentOptionComponent } from "./components/paymentoption/payment-option.component";
import { TaskSummaryComponent } from "./components/summarytaskcard/tasksummary.component";
import { PeachPaymentComponent } from "./components/peachpayment/peachpayment.component";
import { TaskModalComponent } from "./components/task-modal/task-modal.component";
import { IveriComponent } from "./components/iveri/iveri.component";
import { TaskErrorComponent } from "./components/errortaskcard/taskerror.component";
import { PhoneNumberComponent } from "./components/phonenumber/phonenumber.component";
import { UserState } from "./store/state/user.state";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FocusOnShowDirective } from "./directives/focus.directive";
import { PreOrderState } from "./store/state/preorder.state";
import { ProductState } from "./store/state/product.state";
import { TermsModalComponent } from "./components/termsmodal/terms-modal.component";
import { PeachModalComponent } from "./components/peach-modal/peach-modal.component";
import { AuthState } from "./store/state/auth.state";

@NgModule({
  declarations: [
    IveriComponent,
    IveriModalComponent,
    PaygateModalComponent,
    TaskModalComponent,
    TaskCardComponent,
    TaskComponent,
    PaymentOptionComponent,
    TaskSummaryComponent,
    PeachPaymentComponent,
    PeachModalComponent,
    TaskErrorComponent,
    PhoneNumberComponent,
    FocusOnShowDirective,
    TermsModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsModule.forRoot(
      [
        PreOrderState,
        TaskState,
        VendorState,
        UserState,
        ProductState,
        AuthState
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          suppressErrors: false,
          injectContainerState: false
        }
      }
    ),
    // devtools always last
    //NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['vendor', 'user', 'auth']
    }),
  ],
  exports: [
    TaskModalComponent,
    IveriModalComponent,
    PaygateModalComponent,
    PeachPaymentComponent,
    PeachModalComponent,
    PhoneNumberComponent,
    PaymentOptionComponent,
    FocusOnShowDirective,
    TaskErrorComponent,
    TaskSummaryComponent,
    TermsModalComponent,
  ],
  providers: [
    ...services
  ]

})
export class AllxsModule { }
