import { Inject, Injectable, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, map, Observable } from "rxjs";
import { DatePipe, DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import { config } from "../app.config";

import { EventModel } from "../models/event/event.model";
import { VendorModel } from "../models/vendor/vendor.model";
import { ProductModel } from "../models/product/product.model";
import { HoldSeatModel } from "../models/event/hold-seat.model";
import { EventStateModel } from "../models/event/event_state.model";
import { HeldForSaleModel } from "../models/event/heldseats.model";
import { environment } from "@env/environment";
import { VendorStoreModel } from "../models/vendor/vendor-store.model";
import { MenuModel } from "../models/preorder/menu.model";
import * as moment from "moment";
import { TimeSlotInventoryDataModel, TimeSlotInventoryUpdateDataModel } from "src/@allxs/model/inventory/timeslotinventorydata.model";
import { TimeSlotInventoryModel } from "src/@allxs/model/inventory/timeslotinventory.model";
import { InventoryModel } from "src/@allxs/model/inventory/inventory.model";


@Injectable()
export class VendorService implements OnInit, OnDestroy {
  public subscribed: boolean;

  public vendorsSubject = new BehaviorSubject<Array<VendorModel>>([]);
  public productsSubject = new BehaviorSubject<Array<ProductModel>>([]);
  public currentVendors: Array<VendorModel>;
  private hasVendors = false;
  private hasProducts = false;
  private productFilter: string = "";

  constructor(private router: Router,
    @Inject(DOCUMENT) private document: any,
    private http: HttpClient) {
    this.subscribed = false;
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.currentVendors = [];
    this.hasVendors = false;
    this.hasProducts = false;
    this.vendorsSubject.next(this.currentVendors);
    this.productsSubject.next([]);
  }

  getVendorById(vendorId: number): Observable<VendorStoreModel> {
    var observable = new Observable<VendorStoreModel>();
    return observable;
  }

  public holdSeats(clientId: number, seats: HoldSeatModel) {
    return new Observable<EventModel>(subscriber => {
      this.http.post<EventModel>(config.TRIOSAPI + '/api/events/holdseats?clientId=' + clientId, seats)
        .subscribe({
          next: (data: any) => {
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }
        }
        );
    });
  }

  public getEventState(eventId: number) {
    return new Observable<EventStateModel>(subscriber => {
      this.http.get<EventStateModel>(config.TRIOSAPI + '/api/events/geteventstate?eventId=' + eventId)
        .subscribe({
          next: (data: any) => {
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }
        }
        );
    });
  }

  public setEventTicketSaleHold(eventId: number, sessionId: string) {
    return new Observable<HeldForSaleModel>(subscriber => {
      this.http.post<HeldForSaleModel>(config.TRIOSAPI + '/api/events/setticketsholdforsale?eventId=' + eventId + "&sessionId=" + sessionId, null)
        .subscribe({
          next: (data: any) => {
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }
        }
        );
    });
  }

  public getMenus(vendorId: number, userId: string, startDate: Date, endDate: Date) {
    var urlString = config.TRIOSAPI + "/api/preorder/getmenus?vendorId=" + vendorId + "&userId=" + userId + "&startDate=" + moment(startDate).format("DD MMM YYYY") + "&endDate=" + moment(endDate).format("DD MMM YYYY");
    return this.http.get<Array<MenuModel>>(urlString);
  }
    
    // return new Observable<Array<MenuModel>>(subscriber => {
    //   this.http.get<Array<MenuModel>>(urlString)
    //     .pipe(
    //       map(response => {
    //         return response;
    //       })
    //     )
    //     .subscribe(
    //       data => {
    //         if ((data == null) || (data == undefined))
    //           return;
    //         subscriber.next(data);
    //       },
    //       response => {
    //         subscriber.error(response);
    //       }
    //     );
    // });
 
 
 
    public getProductinventory(productId: number): Observable<TimeSlotInventoryDataModel>{
      var urlString = config.TRIOSAPI + "/api/vendorinventory/getproductinventory?productId=" + productId;
      return this.http.get<TimeSlotInventoryDataModel>(urlString);
    }

    public holdTimeSlotInventory(productId: number, timeSlotData: TimeSlotInventoryModel ): Observable<TimeSlotInventoryUpdateDataModel>{
      var urlString = config.TRIOSAPI + "/api/vendorinventory/holdtimeslotinventory?productId=" + productId;
      return this.http.post<TimeSlotInventoryUpdateDataModel>(urlString, timeSlotData);
    }

    public releaseTimeSlotInventory(productId: number,inventoryId: string, timeSlotData: TimeSlotInventoryModel ): Observable<TimeSlotInventoryUpdateDataModel>{
      var urlString = config.TRIOSAPI + "/api/vendorinventory/releasetimeslotinventory?productId=" + productId +"&inventoryId=" + inventoryId;
      return this.http.post<TimeSlotInventoryUpdateDataModel>(urlString, timeSlotData);
    }

}