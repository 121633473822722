import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services";
import { inject, Injectable } from "@angular/core";
import { Store as ngsxStore } from "@ngxs/store";

@Injectable()
export class AuthGuard implements CanActivate{

    constructor(private router: Router, private store: ngsxStore) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const isLoggedIn =  this.store.selectSnapshot(state => state.auth.is_logged_in);

        if (!isLoggedIn) {
            this.router.navigate(["home/auth/login"]);
            return false;
        }

        var role = route.paramMap.get('role');
        if (role === null) {
            return true;
        }
        console.log("canAvtivate false");
        return true;
    }


}
