import { Component, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ClientModel } from '@app/core/models/client/client.model';
import { UserModel } from '@app/core/models/user/user.model';
import { isLoggedInSelector } from '@app/core/store/auth/selector';
import { clientSelector } from '@app/core/store/client/selectors';
import { getUserSelector } from '@app/core/store/user/selectors';
import { AppStateInterface } from '@app/core/types/appState.interface';
import { Menu, NavService } from '@app/shared/services/nav.service';
import { Store, select } from '@ngrx/store';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AuthState } from 'src/@allxs/store/state/auth.state';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminSidebarComponent {

  public themeLogo: string = 'assets/images/logos/default/logo.png'; // Default Logo

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;

  public balance: number = 0;
  public rewardsBalance: number = 0;
  public firstName: string = "";
  public lastName: string = "";
  public accountNumber: number = 0;
  public userPhoto: string;

  public isLoggedIn: boolean = false;
  private subscription: Subscription;
  private userData: UserModel;
  private clientData: ClientModel;
  @Select(AuthState.isLoggedIn) isLoggedIn$: Observable<boolean>;
  
  constructor(private router: Router, public navServices: NavService,
    private store: Store<AppStateInterface>) {
    this.navServices.adminItems.subscribe(menuItems => {
      this.menuItems = menuItems
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url)
              this.setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
              if (subItems.path === event.url)
                this.setNavActive(subItems)
              if (!subItems.children) return false
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems)
              })
            })
          })
        }
      })
    })

    this.subscription = new Subscription();
    this.subscription.add(this.isLoggedIn$.subscribe(data => {
      this.isLoggedIn = data;
   }));


    this.store.pipe(select(clientSelector)).subscribe(data =>{
      if (data){
         this.clientData = data.clientData;
         this.themeLogo = this.clientData.clientLogo;         
      }
    })

    this.subscription.add(
      this.store.pipe(select(getUserSelector)).subscribe(data => {
        if (data) {
          this.userData = data.userData!;
          this.accountNumber = data.userData?.userAccountNumber ?? 0;
          this.firstName = data.userData?.userDetail?.firstName ?? "";
          this.lastName = data.userData?.userDetail?.lastName ?? "";
          this.userPhoto = data.userData?.userPhoto ?? "";
        }
      }));
    
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

}

