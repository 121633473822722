import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ClientVendorModel } from '@app/core/models/client/client-vendor.model';
import { AppStateInterface } from '@app/core/types/appState.interface';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PreOrderUserModalComponent } from '../pre-order-user-modal/pre-order-user-modal.component';
import { Select, Store as ngxStore } from '@ngxs/store';
import { SelectPreOrderVendorAction } from 'src/@allxs/store/actions/preorder.action';

@Component({
  selector: 'app-pre-order-vendor-modal',
  templateUrl: './pre-order-vendor-modal.component.html',
  styleUrls: ['./pre-order-vendor-modal.component.scss']
})
export class PreOrderVendorModalComponent implements OnInit, OnDestroy {

  @ViewChild("preOrderVendorModal", { static: false }) PreOrderVendorModal: TemplateRef<any>;
  public vendors: ClientVendorModel[];
  public subscription: Subscription;

  public closeResult: string;
  public modalOpen: boolean = false;
  modal: NgbModalRef;

  @Output() vendorSelected: EventEmitter<{vendorId: number}> = new EventEmitter<{vendorId: number}>();

  @ViewChild("preOrderUserModal") PreOrderUserModal: PreOrderUserModalComponent;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private store: Store<AppStateInterface>, 
    private _ngxstore: ngxStore) {
  }

  ngOnInit(): void {
  }

  selectedVendor(vendorId) {
    this._ngxstore.dispatch(new SelectPreOrderVendorAction(vendorId));
    this.vendorSelected.emit(vendorId);
    this.modal.dismiss('close');
  }

  async openModal(vendors: ClientVendorModel[]) {
    this.modalOpen = true;
    this.modal = this.modalService.open(this.PreOrderVendorModal, {
      ariaLabelledBy: 'pre-order-vendor-modal',
      centered: true,
      size: 'md',
      windowClass: 'Top',
      backdrop: 'static'
    });

    this.modal.result.then((result) => {
      `Result ${result}`
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.vendors = vendors;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
