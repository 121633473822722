import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  enumerateDaysBetweenDates(startDate: Date, endDate: Date, orderQuantityMap: Map<string, number>): { date: string, month: string, year: string, dayOfWeek: string, orderQuantity: number }[] {
    let dates: { date: string, month: string, year: string, dayOfWeek: string, orderQuantity: number }[] = [];
    let currentDate = new Date(startDate);
    
    while (currentDate.getTime() <= endDate.getTime()) {
      const day = currentDate.getDate();
      const month = this.getMonthAbbreviation(currentDate.getMonth());
      const year = currentDate.getFullYear();
      const dayOfWeek = this.getDayOfWeekAbbreviation(currentDate.getDay());
      const dateKey = `${year}-${month}-${day}`;
  
      let orderQuantity = 0;
      if (orderQuantityMap.has(dateKey)) {
        console.log(dateKey);
        orderQuantity = orderQuantityMap.get(dateKey);
        console.log(orderQuantity);
      }
  
      dates.push({
        date: `${day}`,
        month: month,
        year: `${year}`,
        dayOfWeek: dayOfWeek,
        orderQuantity: orderQuantity
      });

      currentDate.setDate(currentDate.getDate() + 1); // adding one day

    }
  
    return dates;
  }
  

  getMonthAbbreviation(monthIndex: number): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[monthIndex];
  }

  getDayOfWeekAbbreviation(dayIndex: number): string {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return days[dayIndex];
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  getDayOfMonth(date: Date): number {
    return date.getDate();
  }

  getMonth(date: Date): string {
    const monthIndex = date.getMonth();
    const monthsAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthsAbbreviations[monthIndex];
  }

  getDayOfWeek(date: Date): string {
    const dayIndex = date.getDay();
    const daysAbbreviations = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return daysAbbreviations[dayIndex];
  }
  
}
