<ng-template class="theme-modal" #otpModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Verify Phone Number</h5>
            <button [class]="'btn btn-close'" (click)="modal.dismiss('Cross click')">
                <i class="ri-close-line"></i>
            </button>
        </div>
        <div class="modal-body center">
            <div class="row">
                <div class="text-center">
                    <p>Enter OTP:</p>
                    <form [formGroup]="form">
                        <div class="outer-otp">
                            <div id="otp" class="inner-otp">
                                <input id="otpInput" type="text" formControlName="otp" maxlength="6"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    onKeyPress="if(this.value.length==6) return false;" appPrefixFocusAndSelect />
                            </div>
                        </div>
                    </form>
                    <br />
                    <button class="btn btn-primary mx-2" [disabled]="form.disabled || form.invalid" (click)="submitOTP()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>