import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ClientMerchantModel } from '@app/core/models/client/clientmerchant.model';
import { UserModel } from '@app/core/models/user/user.model';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-eft-modal',
  templateUrl: './eft-modal.component.html',
  styleUrls: ['./eft-modal.component.scss']
})
export class EftModalComponent implements OnInit, OnDestroy {

  @ViewChild("eftModal", { static: false }) EftModal: TemplateRef<any>;
  public merchantModel: ClientMerchantModel;
  public linkedAccounts: UserModel[];

  public closeResult: string;
  public modalOpen: boolean = false;
  modal: NgbModalRef;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  async openModal(merchantModel: ClientMerchantModel, linkedAccounts: UserModel[]) {
    this.modalOpen = true;
    this.modal = this.modalService.open(this.EftModal, {
      ariaLabelledBy: 'eft-modal',
      centered: true,
      size: 'md',
      windowClass: 'Top'
    });

    this.modal.result.then((result) => {
      `Result ${result}`
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.merchantModel = merchantModel;
    this.linkedAccounts = linkedAccounts;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
