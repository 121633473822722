<ng-template class="theme-modal" #lookupChildAccountModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Link Child Account!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body center">
            <p>Lookup Child Account</p>
            <p>Account Number</p>
            <br />
        </div>
    </div>
</ng-template>