import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { ClientModel } from '@app/core/models/client/client.model';
import { ThemeImage } from '@app/core/models/client/themeimage.model';
import { clientSelector } from '@app/core/store/client/selectors';
import { AppStateInterface } from '@app/core/types/appState.interface';
import { TeamSlider, TestimonialSlider } from '@app/shared/data/slider';
import { select, Store } from '@ngrx/store';

export interface Service {
  title: string;
  image: string;
  description: string;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  private clientData: ClientModel;

  public mainAboutBanner: string = '';
  public themeLogo: string = '';
  public services: Service[] = [];

  constructor(private store: Store<AppStateInterface>, private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
      if(params['layout']) {
      
      }
    });
  }

  ngOnInit(): void {
  }


}

