import { CartAction } from "@app/core/models/cart/cart-action.model";
import { POMenuOrderModel } from "@app/core/models/preorder-cart/pomenuorder.model";
import { MenuModel } from "@app/core/models/preorder/menu.model";
import { MenuOrderModel } from "@app/core/models/preorder/menuorder.model";
import { UserModel } from "@app/core/models/user/user.model";


export class SelectPreOrderVendorAction {
   static readonly type = '[Pre-Order] Select Vendor';
   constructor(public data: number) { }
}

export class SelectPreOrderDayAction {
   static readonly type = '[Pre-Order] Select Day';
   constructor(public date: string) { }
}
export class SelectPreOrderMenusAction {
   static readonly type = '[Pre-Order] Select Menus';
   constructor(public data: Array<MenuModel>) { }
}

export class SelectPreOrderMenuAction {
   static readonly type = '[Pre-Order] Select Menu';
   constructor(public data: MenuModel) { }
}

export class SelectPreOrderUserAction {
   static readonly type = '[Pre-Order] Select User';
   constructor(public userId: string) { }
}

export class SelectPreOrderCartAction {
   static readonly type = '[Pre-Order] Select Cart';
   constructor(public data: Array<POMenuOrderModel>) { }
}

export class SelectPreOrderDateAction {
   static readonly type = '[Pre-Order] Set Date';
   constructor(public date: any) { }
}

export class GetPreOrderMenusAction {
   static readonly type = '[Pre-Order] Get by vendor Id and user Id';
   constructor(public vendorId: number, public userId: string, public startDate: Date, public endDate: Date) { }
}

export class GetPreOrderCartAction {
   static readonly type = '[Pre-Order] Get by user Id';
   constructor(public userId: string) { }
}

export class AddItemToCartAction {
   static readonly type = '[Pre-Order] Add to Cart';
   constructor(public data: CartAction) { }
}

export class RemoveItemFromCartAction {
   static readonly type = '[Pre-Order] Remove from Cart';
   constructor(public data: CartAction) { }
}

export class PreOrderEmptyCartAction {
   static readonly type = '[Pre-Order] Empty Cart';
   constructor(public userId: string) { }
}


export class GetUserPreOrdersAction {
   static readonly type = '[Pre-Order] Get User Preorders';
   constructor(public vendorId: number, public userId: string) { }
}

