import { createReducer, on } from "@ngrx/store";
import { AuthStateInterface } from "../../types/auth/auth-state.interface";
import * as UserActions from "./actions";
import { UserInterface } from "@app/core/types/user/user.interface";
import { UserStateInterface } from "@app/core/types/user/user-State.interface";
import { UserModel } from "@app/core/models/user/user.model";

export const initialUser: UserInterface ={
 
}

export const userInitialState: UserStateInterface = {
  isLoggedIn: false,
    userName: "",
    user: null,
    error: null,
  };

export const userReducers = createReducer(
    userInitialState, 
    on(UserActions.getUser, (state) => ({ ...state})),
    on(UserActions.setUser, (state, action) => ({ ...state, user: {userData: action.data}})),
    on(UserActions.getUserSuccess, (state, action) => ({ ...state, user: {userData: action.data}}))
);