import { Injectable } from "@angular/core";
import { EventModel } from "../models/event/event.model";
import { OrderResponseModel } from "../models/cart/order-response.model";
import { SeatConfigModel } from "../models/venue/seat-config.model";
import { ProductStoreModel } from "../models/product/productStore.model";
import { HeldForSaleModel } from "../models/event/heldseats.model";

@Injectable()
export class DataService {

    public selectedEvent: EventModel|null;
    public lastStoreOrder: OrderResponseModel;
    public seating: SeatConfigModel;
    public heldSeatsForSale: HeldForSaleModel;
    public cart: Array<ProductStoreModel> = [];
    public cartSessionId: string;
    public events: Array<EventModel> = [];
}
