import { Routes } from '@angular/router';

export const admincontent: Routes = [
    {
      path: 'client-admin',
      loadChildren: () => import('../../admin/admin.module').then(m => m.AdminModule),
      data: {
        breadcrumb: "Client Admin"
      }
    },
  ];