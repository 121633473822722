<ng-template class="theme-modal" #taskPaymentModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Task Payment!</h5>
            <button [class]="'btn btn-close'" (click)="modal.dismiss('Cross click')">
                <i class="ri-close-line"></i>
            </button>
        </div>
        <div class="modal-body center">
            <!-- <div style="width: 100%; display: inline-block; margin-bottom: 10px;">
                <a style="width: 100%; display: inline-block;">
                    <div class="d-flex align-items-start">
                        <div class="media-body">
                            <span>Vendor Name</span>
                            <h2 class="mb-0">{{ task?.amount | currency:'R'}}</h2>
                            <h5 class="f-w-600">Due Date: {{ getTaskDeadline(task.deadline) }}</h5>
                            <p>{{ task?.taskDescription }} </p>
                        </div>
                        <div class="bg-primary b-r-8">
                            <div class="small-box">
                                <i class="fa fa-credit-card-alt fa-1x" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </a>
            </div> -->
            <div *ngIf="mode == 'processing'"
                style="min-height: 100vh; max-height: 50vh; max-width: 100vw; overflow-y: auto">
                <div>
                    We are currently processing your payment!
                </div>
            </div>
            <div *ngIf="mode == 'showerror'">
                <allxs-task-error [task]="selectedTask" [message]="errorMesssage"
                    (onDismiss)="onDismissErrorClick()"></allxs-task-error>
            </div>
            <div *ngIf="mode == 'processingpaymentcomplete'"
                style="min-height: 100vh; max-height: 50vh; max-width: 100vw; overflow-y: auto">
                <div class="row">
                    <div class="col-12">
                        Your payment was sucessfull!
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-default" (click)="onContinue()">Continue</button>
                    </div>
                </div>
            </div>
            <div *ngIf="mode == 'paymentoption'" style="overflow-y: hidden">
                <allxs-payment-option [task]="selectedTask" (onDismiss)="onPaymentFail($event)"
                    (onPayOnAccount)="onPaymentAccount($event)" (onPayBank)="onPaymentBank($event)"
                    (onPayCashless)="onPaymentCashless($event)"></allxs-payment-option>
            </div>
            <div *ngIf="mode == 'payment'"
                style="min-height: 100vh; max-height: 100vh; max-width: 100vw; overflow-y: auto; ">
                <allxs-peachpayment (onPaymentInit)="onPeachInit($event)" (requestCancelled)="onPeachCancelled($event)"
                    (requestComplete)="onPeachComplete($event)"></allxs-peachpayment>
                <!-- <allxs-iveri #ivery (iveriInit)="onIveriInit($event)" (iveriSuccess)="onIveriSuccess($event)" (iveriFail)="onIveriFail($event)"
                   ></allxs-iveri>  -->
            </div>
            <div *ngIf="mode == 'wait'" class="h-100 d-flex align-items-center justify-content-center">
                Please Wait !
            </div>
        </div>
    </div>
</ng-template>