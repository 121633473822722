import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { UserModel } from '@app/core/models/user/user.model';
import { UserService } from '@app/core/services';
import { getUserSelector } from '@app/core/store/user/selectors';
import { AppStateInterface } from '@app/core/types/appState.interface';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pre-order-user-modal',
  templateUrl: './pre-order-user-modal.component.html',
  styleUrls: ['./pre-order-user-modal.component.scss']
})
export class PreOrderUserModalComponent implements OnInit, OnDestroy {

  @ViewChild("preOrderUserModal", { static: false }) PreOrderUserModal: TemplateRef<any>;
  public linkedAccounts: UserModel[];
  public userData: UserModel;
  public subscription: Subscription;

  public closeResult: string;
  public modalOpen: boolean = false;
  modal: NgbModalRef;

  @Output() userSelected: EventEmitter<{userId: string}> = new EventEmitter<{userId: string}>();

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private store: Store<AppStateInterface>) {
  }

  ngOnInit(): void {
  }

  selectedUser(userId) {
    this.userSelected.emit(userId);
    this.modal.dismiss('close');
  }

  async openModal(linkedAccounts: UserModel[]) {
    this.modalOpen = true;
    this.modal = this.modalService.open(this.PreOrderUserModal, {
      ariaLabelledBy: 'pre-order-user-modal',
      centered: true,
      size: 'md',
      windowClass: 'Top',
      backdrop: 'static'
    });

    this.modal.result.then((result) => {
      `Result ${result}`
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.linkedAccounts = linkedAccounts;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
