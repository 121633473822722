import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ClientModel } from '@app/core/models/client/client.model';
import { UserModel } from '@app/core/models/user/user.model';
import { isLoggedInSelector } from '@app/core/store/auth/selector';
import { clientSelector } from '@app/core/store/client/selectors';
import { getUserSelector } from '@app/core/store/user/selectors';
import { AppStateInterface } from '@app/core/types/appState.interface';
import { Menu } from '@app/shared/classes/menu';
import { NavService } from '@app/shared/services/nav.service';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as  AuthActions from '@app/core/store/auth/actions';
import { Store as ngxsStore, Select } from '@ngxs/store';
import { Logout } from 'src/@allxs/store/actions/auth.action';
import { AuthState } from 'src/@allxs/store/state/auth.state';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  public isLoggedIn: boolean;
  public isVendorLanding: boolean;

  public menuItems: Menu[];
  private subscription: Subscription;
  public whatsApp: string | null = null;
  private userData: UserModel;
  private clientData: ClientModel;

  public getScreenWidth: any;
  public getScreenHeight: any;

  
@Select(AuthState.isLoggedIn) isLoggedIn$: Observable<boolean>;

  constructor(
    private router: Router, 
    public navServices: NavService, 
    private store: Store<AppStateInterface>,
    private ngxsStore: ngxsStore) {
    this.subscription = new Subscription();

    this.subscription.add(this.isLoggedIn$.subscribe(data => {
      this.isLoggedIn = data;
   }));

    this.navServices.menuItems.subscribe(menuItems => this.menuItems = menuItems);
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });

    this.subscription.add(this.store.pipe(select(clientSelector)).subscribe(data => {
      if (data) {
        this.clientData = data.clientData;
        if ((this.clientData.whatsappNumber !== null) && (this.clientData.whatsappNumber !== undefined) && (this.clientData.whatsappNumber.length >= 10))
          this.whatsApp = 'https://wa.me/' + this.clientData.whatsappNumber;
      }
    }));

    this.store.pipe(select(clientSelector)).subscribe(data => {
      if (data) {
        this.clientData = data.clientData;

        if (data.vendors.length === 1 &&
          data.clientData.startPage.toLowerCase() !== 'dashboard') {
          let vendor = data.vendors[0];
          if (vendor != null) {
            this.isVendorLanding = true;
          }
        }
      }
    });

    this.subscription.add(
      this.store.pipe(select(getUserSelector)).subscribe(data => {
        if (data) {
          this.userData = data.userData!;
        }
      }));

      if (this.isLoggedIn) {
        this.navServices.updateMenuItems(true, this.isVendorLanding);
      } else {
        this.navServices.updateMenuItems(false, this.isVendorLanding);
      }
  }

  ngOnInit(): void {

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;

    if (item.title == "home") {
      this.navServices.updateMenuItems(false, this.isVendorLanding);
    }

    if (item.title == "support" && this.isLoggedIn) {
      this.onSupport();
    }

    if (item.title == "about us") {
      this.onAbout();
    }

    if (item.title == "log out") {
      this.onLogout();
      this.navServices.updateMenuItems(false, this.isVendorLanding);
    }
  }

  onSupport() {
    var chatString = "text=My%20account%20number%20is%20" + this.userData.userAccountNumber + "%20and%20I%20am%20experiencing%20the%20following%20problem on VIP Ticket!"
    window.open(this.whatsApp + "?" + chatString, "_blank");
  }

  onAbout() {
    window.location.href = "http://www.allxsvip.com/";
  }

  onLogout() {

    this.ngxsStore.dispatch(new Logout("user"));
    this.store.dispatch(AuthActions.logoutUser());

    if (this.isVendorLanding) {
      this.router.navigate(['/home/vendor']);
    } else {
      this.router.navigate(['./']);
    }
  }

}
