import { AppStateInterface } from "@app/core/types/appState.interface";
import { createSelector } from "@ngrx/store";

export const userFeature  = (state: AppStateInterface) => state.user;

export const getUserSelector = createSelector(
    userFeature, 
    (state) => state.user
);

export const errorSelector = createSelector(
    userFeature, 
    (state) => state.error
);

