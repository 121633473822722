import { UserModel } from "@app/core/models/user/user.model";

export class User_SetClientAction {
   static readonly type = '[User] SetClientAction';
   constructor(public clientId : number) {}
}

export class SelectUserLinkedAccountsAction {
    static readonly type = '[User Linked Accounts] Select';
    constructor(public data : UserModel[]) {}
 }
  
 export class GetUserLinkedAccountsAction {
    static readonly type = '[User Linked Accounts] Get User Linked Accounts';
    constructor() {}
 }

 export class SetUserPhoneStep1Action {
   static readonly type = '[USER] Set User Phone Step 1';
   constructor(public phoneNumber: string) {}
}

export class SetUserPhoneStep2Action {
   static readonly type = '[USER] Set User Phone Step 2';
   constructor(public pin: string) {}
}

export class SetPhoneStateAction {
   static readonly type = '[USER] Set Phone State';
   constructor(public state: string) {}
}

export class LoadUserAction {
   static readonly type = '[USER] Load';
   constructor(public userData: UserModel) {}
}

export class User_FetchUserAction {
   static readonly type = '[USER] Fetch';
   constructor() {}
}

export class User_LoadOrdersAction {
   static readonly type = '[USER] LoadOrders';
   constructor(public userId: string, public fromDate: string, public toDate: string) {}
}

export class User_LoadTransactionsAction {
   static readonly type = '[USER] LoadTransactions';
   constructor(public userId: string, public fromDate: string, public toDate: string) {}
}

export class User_ClearData {
   static readonly type = '[USER] Clear Data';
   constructor() {}
}

export class User_UpdateTransactions {
   static readonly type = '[USER] Update User Transactions';
   constructor(public userId: string) {}
}



