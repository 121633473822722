<ng-template class="theme-modal" #eftModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">To topup your account please do the following!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body center">
            <p>1. Do an EFT to the following account:</p>
            <p>Do an EFT Deposit, Bank Transfer or Bank Deposit to the following account details:</p>
            <h4>Company Name: {{ merchantModel.topupCoName }}</h4>
            <h4>Bank Name: {{ merchantModel.topupBank }}</h4>
            <h4>Branch Code: {{ merchantModel.topupBranch }}</h4>
            <h4>Account Number: {{ merchantModel.topupAccount }}</h4>
            <br />
            <p>2. Make sure your reference is the following:</p>
            <ul>
                <li class="list-item" *ngFor="let linkedAccount of linkedAccounts">
                    <div>
                        <h4>For {{ linkedAccount.fullName }}: {{ linkedAccount.userAccountNumber }}</h4>
                    </div>
                </li>
            </ul>

            <p>**Any deposits that do not contain this information will incur an admin fee and delayed payment.</p>
        </div>
    </div>
</ng-template>