import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { POMenuOrderModel } from "@app/core/models/preorder-cart/pomenuorder.model";
import { PreorderCartOrderModel } from "@app/core/models/preorder-cart/preordercartorder.model";
import { MenuModel } from "@app/core/models/preorder/menu.model";
import { PreorderService, UserService, VendorService } from "@app/core/services";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { AddItemToCartAction, GetPreOrderCartAction, GetPreOrderMenusAction, GetUserPreOrdersAction, PreOrderEmptyCartAction, RemoveItemFromCartAction, SelectPreOrderDateAction, SelectPreOrderDayAction, SelectPreOrderMenuAction, SelectPreOrderMenusAction, SelectPreOrderUserAction, SelectPreOrderVendorAction } from "../actions/preorder.action";
import { cloneDeep } from 'lodash-es';
import { SelectedPreorderModel } from "src/@allxs/model/preorder/selected_preorder.model";
import { PreorderModel } from "@app/core/models/preorder/preorder.model";

export class PreOrderStateModel {
    public menus: Array<MenuModel>;
    public selectedMenu: MenuModel;
    public selectedDate: any |null;    
    public selectedOrder : SelectedPreorderModel;
    public userId: string;
    public preOrderMenuOrderModel: Array<POMenuOrderModel>;
    public preOrderCartModel: PreorderCartOrderModel;
    public selectedUserOrders: PreorderModel[];
    
}

var defaultSelected: SelectedPreorderModel = {userId:'', vendorId: 0, menuId: '', dateId: '', data: null};

@State<PreOrderStateModel>({
    name: 'preorder',
    defaults: {
        menus: null,
        selectedMenu: null,
        selectedDate: null,
        userId: '',        
        selectedOrder: defaultSelected,
        preOrderMenuOrderModel: null,
        preOrderCartModel: null,
        selectedUserOrders: []
    }
})

@Injectable()
export class PreOrderState {

    constructor(
        private _store: Store,
        private _preorderService: PreorderService,
        private _userService: UserService,
        private _vendorService: VendorService,
        private _router: Router) {
    }

    @Selector()
    public static menus(state: PreOrderStateModel) {
        return state.menus;
    }

    @Selector()
    public static selectedMenu(state: PreOrderStateModel) {
        return state.selectedMenu;
    }

    @Selector()
    public static selectedUser(state: PreOrderStateModel) {
        return state.userId;
    }

    @Selector()
    public static selectPreOrderMenuOrder(state: PreOrderStateModel) {
        return state.preOrderMenuOrderModel;
    }

    @Selector()
    public static selectPreOrderCart(state: PreOrderStateModel) {
        return state.preOrderCartModel;
    }

    @Selector()
    public static selectPreOrder(state: PreOrderStateModel) {
        return state.selectedOrder;
    }

    @Selector()
    public static selectDate(state: PreOrderStateModel) {
        return state.selectedDate;
    }

    @Selector()
    public static selectedUserOrders(state: PreOrderStateModel) {
        return state.selectedUserOrders;
    }
    
    @Action(SelectPreOrderDayAction)
    public selectedPreorderDayAction(ctx: StateContext<PreOrderStateModel>, action: SelectPreOrderDayAction) {
      ctx.patchState({ selectedDate: action.date });
    }
    

    @Action(SelectPreOrderVendorAction)
    public selectedPreorderVendorAction(ctx: StateContext<PreOrderStateModel>, action: SelectPreOrderVendorAction) {
      var state = ctx.getState();
      if (state.selectedOrder == null) {
        state.selectedOrder = {userId:'', vendorId: 0, menuId: '', dateId: '', data: null};
      }
      var selectedOrder = cloneDeep(state.selectedOrder);
      selectedOrder.vendorId = action.data;
      ctx.patchState({ selectedOrder: selectedOrder});
    }

    @Action(SelectPreOrderMenusAction)
    public selectedMenusAction(ctx: StateContext<PreOrderStateModel>, action: SelectPreOrderMenusAction) {
      ctx.patchState({ menus: action.data });
    }

    @Action(SelectPreOrderMenuAction)
    public selectedMenuAction(ctx: StateContext<PreOrderStateModel>, action: SelectPreOrderMenuAction) {
      var state = ctx.getState();
      var selectedOrder = cloneDeep(state.selectedOrder);
      selectedOrder.menuId = action.data.menuId;     
      selectedOrder.data = state.preOrderCartModel;
      ctx.patchState({ selectedOrder: selectedOrder, selectedMenu: action.data});
     // this._router.navigate(["pre-orders/menu"]);
    }

    @Action(SelectPreOrderDateAction)
    public selectPreOrderDateAction(ctx: StateContext<PreOrderStateModel>, action: SelectPreOrderDateAction) {
      var state = ctx.getState();
      var selectedOrder = cloneDeep(state.selectedOrder);
      selectedOrder.dateId = action.date.date + '-' + action.date.month + '-' + action.date.year;
      selectedOrder.menuId = null;
      selectedOrder.data = state.preOrderCartModel;
      ctx.patchState({ selectedOrder: selectedOrder});
    }

    @Action(SelectPreOrderUserAction)
    public selectedUserAction(ctx: StateContext<PreOrderStateModel>, action: SelectPreOrderUserAction) {
      var state = ctx.getState();
      if (state.selectedOrder == null) {
        state.selectedOrder = {userId:'', vendorId: 0, menuId: '', dateId: '', data: null};
      }
      var selectedOrder = cloneDeep(state.selectedOrder);
      selectedOrder.userId = action.userId;
      selectedOrder.data = state.preOrderCartModel;
      ctx.patchState({ userId: action.userId, selectedOrder: selectedOrder});
    }

    @Action(GetPreOrderMenusAction)
    public getMenusAction(ctx: StateContext<PreOrderStateModel>, action: GetPreOrderMenusAction) {
      return this._vendorService.getMenus(action.vendorId, action.userId, action.startDate, action.endDate).subscribe(data => {
        ctx.patchState({ menus: data });
      });
    }

    @Action(GetPreOrderCartAction)
    public getPreOrderCartAction(ctx: StateContext<PreOrderStateModel>, action: GetPreOrderCartAction) {
      return this._preorderService.getCart(action.userId).subscribe(data => {
        ctx.patchState({ preOrderMenuOrderModel: data });
      });
    }

    @Action(AddItemToCartAction)
    public addItemCartAction(ctx: StateContext<PreOrderStateModel>, action: AddItemToCartAction) {
      return this._preorderService.addItemToCart(action.data).subscribe(data => {
        ctx.patchState({ preOrderMenuOrderModel: data });
      });
    }

    @Action(RemoveItemFromCartAction)
    public removeItemCartAction(ctx: StateContext<PreOrderStateModel>, action: RemoveItemFromCartAction) {
      return this._preorderService.removeItemFromCart(action.data).subscribe(data => {
        ctx.patchState({ preOrderMenuOrderModel: data });
      });
    }

    @Action(PreOrderEmptyCartAction)
    public emptyCartAction(ctx: StateContext<PreOrderStateModel>, action: PreOrderEmptyCartAction) {
      return this._preorderService.emptyCart(action.userId).subscribe(data => {
        ctx.patchState({ preOrderMenuOrderModel: data });
        this._router.navigate(["pre-orders/home"]);
      });
    }


    @Action(GetUserPreOrdersAction)
    public getUserPreordersAction(ctx: StateContext<PreOrderStateModel>, action: GetUserPreOrdersAction) {
      return this._userService.getPreorders(action.vendorId, action.userId).subscribe(data => {
        
        ctx.patchState({ selectedUserOrders: data });
      });
    }

    
}

