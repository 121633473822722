import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { VendorModel } from '@app/core/models/vendor/vendor.model';
import { Router } from '@angular/router';
import { GetVendorAction, SelectVendorAction } from '../actions/vendor.actions';
import { VendorService } from '@app/core/services';
import { VendorStoreModel } from '@app/core/models/vendor/vendor-store.model';
import { TimeSlotInventoryDataModel } from 'src/@allxs/model/inventory/timeslotinventorydata.model';
import { GetProductInventoryAction, HoldInventoryAction, ReleaseInventoryAction } from '../actions/product.actions';
import { tap } from 'rxjs';
import { InventoryModel } from 'src/@allxs/model/inventory/inventory.model';


export class ProductStateModel {
  public selectedProductInventory: TimeSlotInventoryDataModel;
  public selectedProduct: string;
}

@State<ProductStateModel>({
  name: 'product',
  defaults: {
    selectedProductInventory: null,
    selectedProduct: null,
  }
})

@Injectable()
export class ProductState {

  constructor(private _vendorService: VendorService,
    private _router: Router) {
  }

  @Selector()
  public static selectedProductInventory(state: ProductStateModel) {
    return state.selectedProductInventory;
  }

  @Selector()
  public static selectedProduct(state: ProductStateModel) {
    return state.selectedProduct;
  }

  @Action(GetProductInventoryAction)
  public selectVendorAction(ctx: StateContext<ProductStateModel>, action: GetProductInventoryAction) {
    this._vendorService.getProductinventory(action.productId).subscribe(data => {
      ctx.patchState({ selectedProductInventory: data });
    })
  }

  @Action(HoldInventoryAction)
  public holdInventoryAction(ctx: StateContext<ProductStateModel>, action: HoldInventoryAction) {
    return this._vendorService.holdTimeSlotInventory(action.productId, action.timeSlot).pipe(
      tap(data => {
        var state = ctx.getState();
//        var selectedProduct = state.selectedProductInventory.dateSlots[action.timeSlot.dateSlotId].timeSlots[action.timeSlot.id].inventory[action.timeSlot.inventory]
        ctx.patchState({ 
          selectedProductInventory: data,
          selectedProduct: data.updatesInventoryId
        });
      })
    )
  }


  @Action(ReleaseInventoryAction)
  public reeleaseInventoryAction(ctx: StateContext<ProductStateModel>, action: ReleaseInventoryAction) {
        ctx.patchState({ 
          selectedProductInventory: action.timeSlot,
          selectedProduct: null
        });
  }


}
