import { from } from "rxjs";
import { AuthService } from "./auth.service";
import { ClientService } from "./client.service";
import { DataService } from "./data.service";
import { EventService } from "./event.service";
import { PostsService } from "./posts.service";
import { PreorderService } from "./preorder.service";
import { RulesService } from "./rules.service";
import { StorageService } from "./storage.service";
import { StoreService } from "./store.service";
import { TransactionService } from "./transaction.service";
import { UserService } from "./user.service";
import { ValidationService } from "./validation.service";
import { VendorService } from "./vendor.service";
import { VoucherService } from "./voucher.service";

export const services = [
    ClientService,
    AuthService,
    DataService,
    EventService,
    PostsService,
    PreorderService,
    RulesService,
    StoreService,
    StorageService,
    ValidationService,
    VendorService,
    VoucherService,
    TransactionService,
    UserService

]

export * from "./client.service";
export * from "./auth.service";
export * from "./data.service";
export * from "./event.service";
export * from "./posts.service";
export * from "./preorder.service";
export * from "./rules.service";
export * from "./store.service";
export * from "./storage.service";
export * from "./validation.service";
export * from "./vendor.service";
export * from "./voucher.service";
export * from "./transaction.service";
export * from "./user.service";

